import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { HttpMethod } from './constants/http-handlers';
import { AppService } from './services/app.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = "Bandhu-Admin-Portal";

  constructor(public router: Router, private commonService: CommonService, private appService: AppService, private idle: Idle, private keepalive: Keepalive, private actRoute: ActivatedRoute,) {
    idle.setIdle(900);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      this.reset();
    });
    
    idle.onTimeout.subscribe(() => {
      //debugger;
      this.idleState = 'Timed out!';
      this.timedOut = true;
      //this.router.navigate(['/']);
      //this.router.navigate(["/", "login"]); 
      this.sessionOutUser();
    });
    
    idle.onIdleStart.subscribe(() => {
      //debugger;
        this.idleState = 'You\'ve gone idle!'
    });
    idle.onTimeoutWarning.subscribe((countdown) => {
      //debugger;
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
    });
    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();
  }

  reset() {
    //debugger;
      this.idle.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
  }
  sessionOutUser() {
    if (this.actRoute.snapshot['_routerState'].url !== '/login'){
      const url = this.appService.logout;
      const apiObj = {
        url: url,
        methodType: HttpMethod.POST,
      };
      this.commonService.commonApiCall(apiObj, (res, error) => {
        localStorage.clear();
        this.reset();
        this.router.navigate(["/", "login"]); 
      }); 
    }
    else{
      this.reset();
    }
  }

}