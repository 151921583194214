import { formatDate } from '@angular/common';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Options } from '@angular-slider/ngx-slider';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as Highcharts from 'highcharts';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";




@Component({
  selector: 'app-sub-dashboard',
  templateUrl: './sub-dashboard.component.html',
  styleUrls: ['./sub-dashboard.component.scss']
})
export class SubDashboardComponent implements OnInit {

  currentDateTime: any
  currentPage: number;
  filterData: any;
  activeJobCardData: any;
  lassWiseData: any;
  jobcardMonthlyData: any
  sub: Subscription = new Subscription();
  referalcount: any[] = [];
  Phoneno: any;
  state: any;
  startDate: any;
  ReferalVechicalData: any[] = []
  value: number = 0;
  filterFields: any;

  lasswiseFilter: FormGroup;
  mechwiseFilter: FormGroup;
  partsFilter: FormGroup;
  filterObj: any;
  showToastermessage: boolean = false;
  showfinalErrorMsg: string = "";
  isOpendropdown: boolean = false;
  isOpendropdowned: boolean = false;
  partsconsumptionData: any;
  partcons: any;
  mechanicalWiseData: any;
  isMechwiseOpened: boolean = false;
  doorstepedopen: boolean = false;
  masterLob: any;
  lobFilter: any;
  prolifefilter: any;
  activejobFilter: any;
  isLobopended: boolean = false;
  isprolife: boolean = false;

  isActiveopend: any;
  lobdata: any;
  height = 400
  title: string = "";
  graph_type: any = 'PieChart';
  monthss: any;
  jobCount: any;
  vehicleReferalCount: any;
  vehiclereferalguruCount: any;
  openJobCount: any;
  yearmonth: any;
  amount: any;
  guruC: any;
  lassmobileno: any;
  lassname: any;
  lob: any;
  sr: any;
  dealer: any;
  ppl: any;
  emission_norm: any;
  starguru:any;
  pl: any;
  State: any;
  FromDate: string;
  ToDate: string;
  lassnumber: any;
  activegurudatacount: any;
  doorstepbooking: any;
  jobcardcount: any;
  doorstepfilter: any;
  problemname: any;
  prolifeReferalCount: any[];
  prolifereferalguruCount: any[];
  prolifemounth: any[];
  prolifecountdata: any;
  lobData: any;
  ppldata: any;
  pldata: any;
  emissionormdata: any;
  jobstaticsfilter: any;
  isjobstatics: boolean = false;
  ProblemData: any;
  isStateSelected: boolean =false;
  lassName: any = [];
  Region: any;
  region: any;

  regions = [
    "East",
    "North",
    "South",
    "West"
  ];
  activemonth: any[];
  activeguruCount: any[];

  ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: "red",
    bgsPosition: POSITION.bottomCenter,
    bgsSize: 40,
    bgsType: SPINNER.rectangleBounce, // background spinner type
    fgsType: SPINNER.chasingDots, // foreground spinner type
    pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
    pbThickness: 5, // progress bar thickness
  };
  role: any;
 
  zone: any;

  constructor( private fb: FormBuilder, public datepipe: DatePipe, private Dashboard:DashboardService,private ngxLoader: NgxUiLoaderService) { }

 
  public mbarChartLabels: string[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend: boolean = true;

  public barChartData: any[] = [];
  closeCountArray: any[] = [];
  openCountArray: any[] = [];
  monthCountArray: any[] = [];


  

  months = [
    "Jan", "Feb",
    "Mar", "Apr", "May",
    "Jun", "Jul", "Aug",
    "Sep", "Oct",
    "Nov", "Dec"
  ];
  abc = []
  def = []
  options1 = {
    pieHole: 0.5,
    'legend': 'bottom',
    pieSliceBorderColor: "none",
    colors: ['#2caffe', '#666666', '#dadada']

  };

  options2 = {
    pieHole: 0.5,
    'legend': 'bottom',
    pieSliceBorderColor: "none",
    colors: ['#2caffe', '#666666', '#dadada']

  };

  options: Options = {
    floor: 0,
    ceil: 50,
    showSelectionBar: true
  };

  states:any
  
  showfinalSuccessMsg: string;
  stateSelected: boolean = false;
  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem('userDetail')).userdetails.role;
    if (this.role === 'RPM'|| this.role === 'DEALER') {
      this.zone = JSON.parse(localStorage.getItem('userDetail')).userdetails.region;
     
    }
    this.currentDateTime = new Date();
    this.currentDateTime = this.datepipe.transform(this.currentDateTime, 'yyyy-MM-dd');
    this.startDate = new Date();
    this.startDate.setDate(1);
    this.startDate = this.datepipe.transform(this.startDate, 'yyyy-MM-dd');
    this.jobcard_statistics_monthly();
    this.vehical_Referal_Count()
    this.prolife_Referal_Count()
    // this.active_Job_Card();
    this.lassWise_Data('lass');
    this.lassWise_Data('Mechanical');
    this.buildFilterForm();
    this.partConsumptionData();
    this.active_Guru_data();
    this.doorstepBookingData();
    this.dropdown('lob');
    this.dropdown('ppl');
    this.dropdown('pl');
    this.dropdown('emission_norm');
    this.dropdown('problem_name');
    this.dropdown('state');
    this.dropdown('region');
    // this.dropdown('starguru');
    // this.dropdown('sr');

  }
// Define a variable to track the selection status of the "Select Lob" dropdown
lobSelected: boolean = false;

// Method to check if "Select Lob" dropdown has been selected
isLobNotSelected() {
  return !this.lobSelected;
}

// Method to handle selection change in "Select Lob" dropdown
lobSelectionChange() {
  this.lobSelected = true;
}

  buildFilterForm() {
    this.lasswiseFilter = this.fb.group({
      state: [''],
      phonenumber: [''],
      starguru: [''],
      sr: [''],
      dealer: ['']
    })
    this.mechwiseFilter = this.fb.group({
      state: [''],
      phonenumber: [''],
      starguru: [''],
      sr: [''],
      dealer: ['']
    })
    this.partsFilter = this.fb.group({
      from_date: [''],
      to_date: [''],
      lass_mobile_no: [''],
      lass_name: [''],
      lob: [''],
      ppl: [''],
      pl: [''],
      emission_norm: [],
      state: [''],
      region:[''],
      starguru: [''],
      sr: [''],
      dealer: ['']
    })

    this.lobFilter = this.fb.group({
      lobs: [''],
      lass_number: ['',[Validators.required, Validators.pattern('^[0-9]{10}$')]],
      state: [''],
      from_date: [''],
      to_date: [''],
      region:[''],
      starguru: [''],
      sr: [''],
      dealer: ['']
    })
    this.prolifefilter = this.fb.group({
      lobs: [''],
      lass_number: [''],
      state: [''],
      from_date: [''],
      to_date: [''],
      region:[''],
      starguru: [''],
      sr: [''],
      dealer: ['']
    })
    this.activejobFilter = this.fb.group({
      from_date: [''],
      to_date: [''],
      lass_mobile_no: [''],
      lass_name: [''],
      lob: [''],
      ppl: [''],
      pl: [''],
      emission_norm: [],
      state: [''],
      jobcard_count: ['',[Validators.required, Validators.pattern('^[0-9]+$')]],
      region:[''],
      starguru: [''],
      sr: [''],
      dealer: ['']
    })
    this.doorstepfilter = this.fb.group({
      from_date: [''],
      to_date: [''],
      lass_mobile_no: [''],
      problem_name: [''],
      lass_name: [''],
      lob: [''],
      ppl: [''],
      pl: [''],
      emission_norm: [''],
      state_name: [''],
      region:[''],
      starguru: [''],
      sr: [''],
      dealer: ['']
    })

    this.jobstaticsfilter = this.fb.group({
      from_date: [''],
      to_date: [''],
      lass_number: [''],
      problem_name: [''],
      lass_name: [''],
      lob: [''],
      ppl: [''],
      pl: [''],
      emission_norm: [''],
      state_name: [''],
      region:[''],
      starguru: [''],
      sr: [''],
      dealer: ['']
    })
  }
  // Your component file (TypeScript)
  // isOpendropdown: boolean = false;
  openedFilterId: string | null = null;

  openDropdown(filterId: string) {
    this.isOpendropdown = false;
    this.isOpendropdowned = false;
    this.isMechwiseOpened = false;
    this.isLobopended = false;
    this.isActiveopend = false;
    this.doorstepedopen = false;
    this.isprolife = false;
    this.isjobstatics = false
    if (filterId == 'filter1') {
      this.isOpendropdown = true;
    }
    else if (filterId == 'filter2') {
      this.isOpendropdowned = true;
    }
    else if (filterId == 'filter3') {
      this.isLobopended = true
    }
    else if (filterId == 'filter4') {
      this.isMechwiseOpened = true;
    }
    else if (filterId == 'filter5') {
      this.isActiveopend = true;
    }
    else if (filterId == 'filter6') {
      this.doorstepedopen = true;
    }
    else if (filterId == 'filter7') {
      this.isprolife = true;
    }
    else if (filterId == 'filter8') {
      this.isjobstatics = true;
    }
    this.openedFilterId = filterId;
  }

  closeDropdown(filterId: string) {
    if (filterId == 'filter1') {
      this.isOpendropdown = false;
    }
    else if (filterId == 'filter2') {
      this.isOpendropdowned = false;
    }
    else if (filterId == 'filter3') {
      this.isLobopended = false;
    }
    else if (filterId == 'filter4') {
      this.isMechwiseOpened = false;
    }
    else if (filterId == 'filter5') {
      this.isActiveopend = false;
    }
    else if (filterId == 'filter6') {
      this.doorstepedopen = false;
    }
    else if (filterId == 'filter7') {
      this.isprolife = false;
    }
    else if (filterId == 'filter8') {
      this.isjobstatics = false;
    }
    this.openedFilterId = null;
  }

  applyfilter() {
    if (this.isOpendropdown) {
      this.Phoneno = this.lasswiseFilter.controls['phonenumber']?.value
      this.state = this.lasswiseFilter.controls['state']?.value
      this.starguru = this.lasswiseFilter.controls['starguru']?.value
      this.sr = this.lasswiseFilter.controls['sr']?.value
      this.dealer = this.lasswiseFilter.controls['dealer']?.value
      this.isOpendropdown = false;
      if (this.state || this.Phoneno) { this.lassWise_Data('lass'); }
    }
    else if (this.isMechwiseOpened) {
      this.Phoneno = this.mechwiseFilter.controls['phonenumber']?.value
      this.state = this.mechwiseFilter.controls['state']?.value
      this.starguru = this.mechwiseFilter.controls['starguru']?.value
      this.sr = this.mechwiseFilter.controls['sr']?.value
      this.dealer = this.mechwiseFilter.controls['dealer']?.value
      this.isMechwiseOpened = false;
      if (this.state || this.Phoneno) { this.lassWise_Data('Mechanical'); }
    }
    else if (this.isOpendropdowned) {
      // this.partcons = this.partsFilter.controls['partsconsumption']?.value;
      this.lassmobileno = this.partsFilter.controls['lass_mobile_no']?.value;
      this.lassname = this.partsFilter.controls['lass_name']?.value;
      this.lob = this.partsFilter.controls['lob']?.value;
      this.ppl = this.partsFilter.controls['ppl']?.value;
      this.pl = this.partsFilter.controls['pl']?.value;
      this.emission_norm = this.partsFilter.controls['emission_norm']?.value;
      this.State = this.partsFilter.controls['state']?.value;
      this.region=this.partsFilter.controls['region']?.value;
      this.starguru=this.partsFilter.controls['starguru']?.value;
      this.sr = this.partsFilter.controls['sr']?.value
      this.dealer = this.partsFilter.controls['dealer']?.value
      if (this.State) {
        this.isStateSelected = true;
      } else {
        this.isStateSelected = false;
      }
      this.isOpendropdowned = false;
      this.partConsumptionData();
    }
    else if (this.isActiveopend) {
      // this.partcons = this.partsFilter.controls['partsconsumption']?.value;
      this.lassmobileno = this.activejobFilter.controls['lass_mobile_no']?.value;
      this.lassname = this.activejobFilter.controls['lass_name']?.value;
      this.lob = this.activejobFilter.controls['lob']?.value;
      this.ppl = this.activejobFilter.controls['ppl']?.value;
      this.pl = this.activejobFilter.controls['pl']?.value;
      this.emission_norm = this.activejobFilter.controls['emission_norm']?.value;
      this.State = this.activejobFilter.controls['state']?.value;
      this.jobcardcount = this.activejobFilter.controls['jobcard_count']?.value
      this.region=this.activejobFilter.controls['region']?.value;
      this.starguru=this.activejobFilter.controls['starguru']?.value;
      this.sr = this.activejobFilter.controls['sr']?.value
      this.dealer = this.activejobFilter.controls['dealer']?.value

      this.isActiveopend = false;
      this.active_Guru_data();
    }
    else if (this.isLobopended) {
      this.lobdata = this.lobFilter.controls['lobs']?.value;
      this.lassnumber = this.lobFilter.controls['lass_number'].value;
      this.state = this.lobFilter.controls['state'].value;
      this.region=this.lobFilter.controls['region']?.value;
      this.starguru=this.lobFilter.controls['starguru']?.value;
      this.sr = this.lobFilter.controls['sr']?.value
      this.dealer = this.lobFilter.controls['dealer']?.value

      this.isLobopended = false; 
      this.vehical_Referal_Count();
    }
    else if (this.isprolife) {
      this.lobdata = this.prolifefilter.controls['lobs']?.value;
      this.lassnumber = this.prolifefilter.controls['lass_number'].value;
      this.state = this.prolifefilter.controls['state'].value;
      this.region=this.prolifefilter.controls['region']?.value;
      this.starguru=this.prolifefilter.controls['starguru']?.value;
      this.sr = this.prolifefilter.controls['sr']?.value
      this.dealer = this.prolifefilter.controls['dealer']?.value
      this.isprolife = false;
      this.prolife_Referal_Count();
    }
    else if (this.doorstepedopen) {
      // this.partcons = this.partsFilter.controls['partsconsumption']?.value;
      this.lassmobileno = this.doorstepfilter.controls['lass_mobile_no']?.value;
      this.lassname = this.doorstepfilter.controls['lass_name']?.value;
      this.problemname = this.doorstepfilter.controls['problem_name']?.value;
      this.lob = this.doorstepfilter.controls['lob']?.value;
      this.ppl = this.doorstepfilter.controls['ppl']?.value;
      this.pl = this.doorstepfilter.controls['pl']?.value;
      this.emission_norm = this.doorstepfilter.controls['emission_norm']?.value;
      this.State = this.doorstepfilter.controls['state_name']?.value;
      this.region=this.doorstepfilter.controls['region']?.value;
      this.starguru=this.doorstepfilter.controls['starguru']?.value;
      this.sr = this.doorstepfilter.controls['sr']?.value
      this.dealer = this.doorstepfilter.controls['dealer']?.value
      this.doorstepedopen = false;
      this.doorstepBookingData();
    }
    else if (this.isjobstatics) {
      // this.partcons = this.partsFilter.controls['partsconsumption']?.value;
      this.lassmobileno = this.jobstaticsfilter.controls['lass_number']?.value;
      this.lassname = this.jobstaticsfilter.controls['lass_name']?.value;
      this.problemname = this.jobstaticsfilter.controls['problem_name']?.value;
      this.lob = this.jobstaticsfilter.controls['lob']?.value;
      this.ppl = this.jobstaticsfilter.controls['ppl']?.value;
      this.pl = this.jobstaticsfilter.controls['pl']?.value;
      this.emission_norm = this.jobstaticsfilter.controls['emission_norm']?.value;
      this.State = this.jobstaticsfilter.controls['state_name']?.value;
      this.region=this.jobstaticsfilter.controls['region']?.value;
      this.starguru=this.jobstaticsfilter.controls['starguru']?.value;
      this.sr = this.jobstaticsfilter.controls['sr']?.value
      this.dealer = this.jobstaticsfilter.controls['dealer']?.value
   
      if (this.region) {
        
        this.State = '';
    }
      this.isjobstatics = false;
      this.jobcard_statistics_monthly();
    }
  }

  reset(flag: any) {
    this.Phoneno='';
    this.lassmobileno = '';
    this.lassname = '';
    this.lob = '';
    this.ppl = '';
    this.pl = '';
    this.emission_norm = '';
    this.State = '';
    this.jobcardcount = '';
    this.problemname = '';
    this.state = '';
    this.lobData = '';
    this.region=''
    this.dropdown('lob');
    this.dropdown('ppl');
    this.dropdown('pl');
    this.dropdown('emission_norm');
    this.dropdown('problem_name');
    this.dropdown('state');
    this.dropdown('region');
    if (flag == "lasswiseFilter") {
      this.starguru='';
      this.sr = '';
      this.dealer = '';
      this.lasswiseFilter.reset();
      this.lassWise_Data('lass');

    }
    else if (flag == "jobstaticsfilter") {
      this.lassmobileno = '';
      this.lassname = '';
      this.problemname = '';
      this.lob = '';
      this.ppl = '';
      this.pl = '';
      this.emission_norm = '';
      this.State = '';
      this.region=''
      this.starguru='';
      this.sr = '';
      this.dealer = '';
      this.jobstaticsfilter.reset();
      this.jobcard_statistics_monthly();
    }
    else if (flag == "mechwiseFilter") {
      this.starguru='';
      this.sr = '';
      this.dealer = '';
      this.mechwiseFilter.reset();
      this.lassWise_Data('Mechanical');
    }
    else if (flag == "lobFilter") {
      this.lobdata = '';
      this.lassnumber = '';
      this.state = '';
      this.region=''
      this.starguru = ''
      this.sr = '';
      this.dealer = '';
      this.lobFilter.reset();
      this.vehical_Referal_Count()
    }

    else if (flag == "prolifefilter") {
      this.lobdata = '';
      this.lassnumber = '';
      this.state = '';
      this.region=''
      this.starguru = ''
      this.sr = '';
      this.dealer = '';
      this.prolifefilter.reset();
      this.prolife_Referal_Count()
    }
    else if (flag == "activejobFilter") {
      this.lassmobileno = '';
      this.lassname = '';
      this.lob = '';
      this.ppl = '';
      this.pl = '';
      this.region=''
      this.emission_norm = '';
      this.State = '';
      this.jobcardcount = ''
      this.starguru = ''
      this.sr = '';
      this.dealer = '';
      this.activejobFilter.reset();
      this.active_Guru_data()
    }
    else if (flag == "doorstepfilter") {
      this.lassmobileno = '';
      this.lassname = '';
      this.problemname = '';
      this.lob = '';
      this.ppl = '';
      this.pl = '';
      this.region=''
      this.emission_norm = '';
      this.State = '';
      this.starguru = '';
      this.sr = '';
      this.dealer = '';
      this.doorstepfilter.reset();
      this.doorstepBookingData();
    }

    else {
      this.lassmobileno = '';
      this.lassname = '';
      this.lob = '';
      this.ppl = '';
      this.pl = '';
      this.region=''
      this.emission_norm = '';
      this.state = '';
      this.starguru =''
      this.sr = '';
      this.dealer = '';
      this.partsFilter.reset();
      this.partConsumptionData();

    }
    // this.formEventTrigger.emit();
    this.isjobstatics = false;
    this.isOpendropdown = false;
    this.isOpendropdowned = false;
    this.isLobopended = false;
    this.doorstepedopen = false;
    this.isActiveopend = false;
    this.isprolife = false;
    this.isMechwiseOpened = false;


  }
  // formData: { phoneNo: string, state: string } = { phoneNo: '', state: '' };
  getDateMonth(dateValue) {
    let temp = this.datepipe.transform(dateValue, 'yyyy-MM-dd')
    const date = new Date(temp);
    return this.months[date.getMonth()];
  }
  barGraph: any;
  jobcard_statistics_monthly() {
    try {
      this.ngxLoader.startLoader('loader-01');
      this.monthss = []
      this.jobCount = []
      this.openJobCount = []
      this.barChartData = [];
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const twoMonthsAgo = new Date(currentYear, currentMonth - 2, 1); // Subtract 2 from the current month

      // Format the dates using DatePipe
      this.FromDate = this.datepipe.transform(twoMonthsAgo, 'yyyy-MM-dd');
      this.ToDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');

      // Prepare the data object with the calculated dates
      let data = {
        "from_date": this.filterData ? this.filterData.fromDate : this.FromDate,
        "to_date": this.filterData ? this.filterData.toDate : this.ToDate,
        "problem_name":this.problemname?this.problemname:this.filterData ? this.filterData.problem_name :[],
        "lass_number":this.lassmobileno?this.lassmobileno:this.filterData ? this.filterData.lass_number :'',
        "lass_name": this.filterData ? this.filterData.lass_name:this.lassname?this.lassname:'',
        "lob":this.lob ? this.lob :this.filterData ? this.filterData.lob:[],
        "ppl": this.ppl ? this.ppl:this.filterData ? this.filterData.ppl:[],
        "pl": this.pl ? this.pl:this.filterData ? this.filterData.pl:[],
        "emission_norm": this.emission_norm ? this.emission_norm:this.filterData ? this.filterData.emission_norm:[],
        "state_name": this.State ? this.State:this.filterData ? this.filterData.state_name:[],
        "zone": this.role === 'RPM'||this.role === 'DEALER' ? this.zone : this.region ? this.region : '',
        "is_star_guru": this.starguru ? this.starguru: this.filterData? this.filterData.is_star_guru:[],
        "service_type":  this.sr?this.sr:this.filterData ? this.filterData.service_type:[],
        "dealers": this.dealer 
          ? [this.dealer.toUpperCase()] 
          : this.filterData && Array.isArray(this.filterData.dealers) 
          ? this.filterData.dealers.map((d: string) => d.toUpperCase()) 
          : [],
      }



      this.sub.add(this.Dashboard.Dashboardapi(data).subscribe({
        next: (res: any) => {
          if (res.success===true) {
            this.ngxLoader.stopLoader('loader-01');
            this.jobcardMonthlyData = res.result;
          

            this.jobcardMonthlyData.sort((a, b) => {
              const aMonth = a.year_month.split(' ')[0];
              const bMonth = b.year_month.split(' ')[0];
              const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              return months.indexOf(aMonth) - months.indexOf(bMonth);
          });
          
          this.jobcardMonthlyData.forEach(element => {
              this.jobCount.push(element.total_jobcard_count);
              this.openJobCount.push(element.total_closed_jobcard_count);
              this.monthss.push(element.year_month);
          });

            // this.jobcardMonthlyData.forEach(element => {
            //   this.monthss.push(element.year_month)
            //   this.jobCount.push(element.total_jobcard_count)
            //   this.openJobCount.push(element.total_closed_jobcard_count)
            // });

        
            let id = document.getElementById('JobCountGraph');
            let openJobCount = Math.max(...this.jobCount);
            let Closejobcount = Math.max(...this.openJobCount);
            let maxYAxis = Math.max(openJobCount, Closejobcount);
            this.barGraph = {
              chart: {
                type: 'column'
              },
              title: {
                text: null,
                align: 'left'
              },
              xAxis: {
                categories: this.monthss,
                crosshair: true,
                accessibility: {
                  description: 'Month & year'
                }
              },
              yAxis: {
                min: 0,
                max: maxYAxis,
                ceiling: maxYAxis,
                title: {
                  text: ''
                }
              },
              credits: {
                enabled: false
              },

              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  // pointWidth: 50, // Adjust the width as needed
                  grouping: true,
                  shadow: false,
                  // borderWidth: 0
                }
              },
              series: [
                // {
                //   name: ' Total Jobcard Count ',
                //   data: this.jobCount,
                //   color:'rgba(44,175,254,255)',
                //   tooltip: {
                //     shared: true,
                //     pointFormatter: function () {
                //       let value = this.y;
                //       if (value >= 10000000) {
                //         value = (value / 10000000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Cr';
                //       } else if (value >= 100000) {
                //         value = (value / 100000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Lakh';
                //       } else {
                //         value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //       }
                //       return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + value + '</b><br/>';
                //     }
                //   },
                // },
                {
                  name: 'Total Closed Jobcard Count ',
                  data: this.openJobCount,
                  color: 'rgba(84,79,197,255)',
                  tooltip: {
                    shared: true,
                    pointFormatter: function () {
                      let value = this.y;
                      if (value >= 10000000) {
                        value = (value / 10000000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Cr';
                      } else if (value >= 100000) {
                        value = (value / 100000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Lakh';
                      } else {
                        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }
                      return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + value + '</b><br/>';
                    }
                  },
                },
               
              ]
            };

            Highcharts.chart(id, this.barGraph)
          }
          else if (res.success === false && res.result === "Data Not Found") {
            this.ngxLoader.stopLoader('loader-01');
            let id = document.getElementById('JobCountGraph');
            id.innerHTML = "<div style='text-align: center;'>No Data Found</div>";
          } else {
            // Handle other cases if needed, or leave this block empty
            this.ngxLoader.stopLoader('loader-01');
          }
        }
      }))
    } catch (error) {
      // Handle the error
      this.ngxLoader.stopLoader('loader-01');


    }


  }

  vehiclereferalGraph: any;
  referalmounth: any;
  chart: any;
  chartOptions:any

  vehical_Referal_Count() {
    try {
      this.ngxLoader.startLoader('loader-06');
      this.vehicleReferalCount = [];
      this.vehiclereferalguruCount = [];
      this.referalmounth = [];
      let data = {
        "from_date": this.filterData ? this.filterData.fromDate : this.FromDate,
        "to_date": this.filterData ? this.filterData.toDate : this.ToDate,
        "action_type": "vehicle_referral",
        "state": this.state ? this.state :this.filterData ? this.filterData.state_name:[],
        "lass_number":  this.filterData ? this.filterData.lass_number :this.lassnumber ? this.lassnumber:'',
        "lob": this.lobdata ? this.lobdata :this.filterData ? this.filterData.lob :[],
        "zone": this.role === 'RPM'||this.role === 'DEALER' ? this.zone : this.region ? this.region : '',
        "is_star_guru": this.starguru ? this.starguru: this.filterData? this.filterData.is_star_guru:[],
      //  "service_type":  this.sr?this.sr:this.filterData ? this.filterData.service_type:[],
       "dealers": this.dealer 
          ? [this.dealer.toUpperCase()] 
          : this.filterData && Array.isArray(this.filterData.dealers) 
          ? this.filterData.dealers.map((d: string) => d.toUpperCase()) 
          : [],
      };
      this.sub.add(this.Dashboard.Vechical_Referal_count(data).subscribe({
        next: (res: any) => {
          if (res.success === true) {
            this.ngxLoader.stopLoader('loader-06');
            this.referalcount = res.result;
          
            // this.referalcount.forEach(element => {
            //     this.referalmounth.push(element.year_month);
            //     this.vehicleReferalCount.push(element.vehicle_referral_count);
            //     this.vehiclereferalguruCount.push(element.guru_count);
            
            // });

            let monthDataMap = {};
            this.referalcount.forEach(element => {
              monthDataMap[element.year_month] = {
                vehicle_referral_count: element.vehicle_referral_count,
                guru_count: element.guru_count
              };
              this.referalmounth.push(element.year_month);
            });

            this.referalmounth.sort((a, b) => {
              const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              return months.indexOf(a.split(' ')[0]) - months.indexOf(b.split(' ')[0]);
            });
  
            this.referalmounth.forEach(month => {
              let dataPoint = monthDataMap[month];
              if (dataPoint) {
                this.vehicleReferalCount.push(dataPoint.vehicle_referral_count);
                this.vehiclereferalguruCount.push(dataPoint.guru_count);
              } else {
                this.vehicleReferalCount.push(0);
                this.vehiclereferalguruCount.push(0);
              }
            });
          //   this.referalmounth.sort((a, b) => {
          //     const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          //     return months.indexOf(a.split(' ')[0]) - months.indexOf(b.split(' ')[0]);
          // });
            let id = document.getElementById('vehiclerefralgraph');
            let vehiclerefcount = Math.max(...this.vehicleReferalCount);
            let Vgurucount = Math.max(...this.vehiclereferalguruCount);
            let maxYAxis = Math.max(vehiclerefcount, Vgurucount);

            this.vehiclereferalGraph = {
              chart: {
                  zoomType: 'xy'
              },
              title: {
                  text: null,
                  align: 'left'
              },
              subtitle: {

                  align: 'left'
              },
              plotOptions: {
                  column: {
                      pointWidth: 50, // Adjust the width as needed
                      grouping: true,
                      shadow: false,
                      borderWidth: 0
                  }
              },
              credits: {
                  enabled: false
              },
              xAxis: [{
                categories: this.referalmounth,
                  crosshair: true
              }],
              yAxis: [{ // Secondary yAxis
                allowDecimals: false,
                      title: {
                          text: ' Guru Count ',
                          style: {
                              color: Highcharts.getOptions().colors[0]
                          },
                          
                      },
                      labels: {
                          format: '{value:.0f}',
                          style: {
                              color: Highcharts.getOptions().colors[0]
                          }
                      },
                      opposite: true,
                      // min: 0, // Ensure yAxis starts from 0

                  },
                  { // Primary yAxis
                    allowDecimals: false,
                      labels: {
                          format: '{value:.0f}',
                          style: {
                              color: Highcharts.getOptions().colors[1]
                          }
                      },
                      title: {
                          text: 'Vehicle Referral Count',
                          style: {
                              color: Highcharts.getOptions().colors[1]
                          }
                      },
                      // opposite: true,
                      // min: 0 // Ensure yAxis starts from 0
                  },
              ],
              tooltip: {
                  shared: true
              },
              legend: {
                align: 'center',
                layout: 'horizontal',
                verticalAlign: 'bottom',
                y: 20, // Adjust the y position as needed
                backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
            },
              series: [{
                      name: ' Guru Count',
                      type: 'spline',
                      data: this.vehiclereferalguruCount,
                      color:'rgba(44,175,254,255)',
                      tooltip: {
                        shared: true,
                        pointFormatter: function () {
                          let value = this.y;
                          if (value >= 10000000) {
                            value = (value / 10000000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Cr';
                          } else if (value >= 100000) {
                            value = (value / 100000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Lakh';
                          } else {
                            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          }
                          return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + value + '</b><br/>';
                        }
                      },
                  },
                  {
                      name: 'Vehicle Referral Count',
                      type: 'column',
                      yAxis: 1,
                      data: this.vehicleReferalCount,
                      color: 'rgba(84,79,197,255)',
                      tooltip: {
                        shared: true,
                        pointFormatter: function () {
                          let value = this.y;
                          if (value >= 10000000) {
                            value = (value / 10000000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Cr';
                          } else if (value >= 100000) {
                            value = (value / 100000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Lakh';
                          } else {
                            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          }
                          return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + value + '</b><br/>';
                        }
                      },

                  },

              ]
          };

          Highcharts.chart(id, this.vehiclereferalGraph);
        } else if (res.success === false && res.msg === "Data Not Found") {
          this.ngxLoader.stopLoader('loader-06');
          let id = document.getElementById('vehiclerefralgraph');
          id.innerHTML = "<div style='text-align: center;'>No Data Found</div>";
        } else {
          this.vehicleReferalCount = [];
          this.vehiclereferalguruCount = [];
          this.ngxLoader.stopLoader('loader-06');
        }
        },
        error: (error: any) => {
     
          this.ngxLoader.stopLoader('loader-06');
        }
      }));
    } catch (error) {
      
      this.ngxLoader.stopLoader('loader-06');
    }
  }

 
  prolifereferalGraph: any
  prolife_Referal_Count() {
    try {
      this.ngxLoader.startLoader('loader-05');
      this.prolifeReferalCount = []
      this.prolifereferalguruCount = []
      this.prolifemounth = []
      let data = {
        "action_type": "prolife_referral",
        "from_date": this.filterData ? this.filterData.fromDate : this.FromDate,
        "to_date": this.filterData ? this.filterData.toDate : this.ToDate,
        "state": this.state ? this.state :this.filterData ? this.filterData.state_name:[],
        "lass_number": this.lassnumber ? this.lassnumber: this.filterData ? this.filterData.lass_number :'',
        "lob": this.lobdata ? this.lobdata :this.filterData ? this.filterData.lob :[],
        "zone": this.role === 'RPM'||this.role === 'DEALER' ? this.zone : this.region ? this.region : '',
        "is_star_guru": this.starguru ? this.starguru: this.filterData? this.filterData.is_star_guru:[],
        // "service_type":  this.sr?this.sr:this.filterData ? this.filterData.service_type:[],
        "dealers": this.dealer 
          ? [this.dealer.toUpperCase()] 
          : this.filterData && Array.isArray(this.filterData.dealers) 
          ? this.filterData.dealers.map((d: string) => d.toUpperCase()) 
          : [],
      };

      this.sub.add(this.Dashboard.Vechical_Referal_count(data).subscribe({
        next: (res: any) => {
          if (res.success === true) {
            this.ngxLoader.stopLoader('loader-05');
            this.prolifecountdata = res.result;
            let monthDataMap = {};
            this.prolifecountdata.forEach(element => {
              monthDataMap[element.year_month] = {
                prolife_referral_count: element.prolife_referral_count,
                guru_count: element.guru_count
              };
              this.prolifemounth.push(element.year_month);
            });

            this.prolifemounth.sort((a, b) => {
              const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              return months.indexOf(a.split(' ')[0]) - months.indexOf(b.split(' ')[0]);
            });
  
            this.prolifemounth.forEach(month => {
              let dataPoint = monthDataMap[month];
              if (dataPoint) {
                this.prolifeReferalCount.push(dataPoint.prolife_referral_count);
                this.prolifereferalguruCount.push(dataPoint.guru_count);
              } else {
                this.prolifeReferalCount.push(0);
                this.prolifereferalguruCount.push(0);
              }
            });

// this.prolifecountdata.forEach(element => {
//     this.prolifemounth.push(element.year_month)
//     this.prolifeReferalCount.push(element.prolife_referral_count)
//     this.prolifereferalguruCount.push(element.guru_count)
// });


   
            
            let id = document.getElementById('proliferefralgraph');
            let prolifecount = Math.max(...this.prolifeReferalCount);
            let gurucount = Math.max(...this.prolifereferalguruCount);
            let maxYAxis = Math.max(prolifecount, gurucount);
       

            this.prolifereferalGraph = {
              chart: {
                  zoomType: 'xy'
              },
              title: {
                  text: null,
                  align: 'left'
              },
              subtitle: {

                  align: 'left'
              },
              plotOptions: {
                  column: {
                      pointWidth: 50, // Adjust the width as needed
                      grouping: true,
                      shadow: false,
                      borderWidth: 0
                  }
              },
              credits: {
                  enabled: false
              },
              xAxis: [{
                categories: this.prolifemounth,
                  crosshair: true
              }],
              yAxis: [{ // Secondary yAxis
                allowDecimals: false,
                      title: {
                          text: 'Guru Count ',
                          style: {
                              color: Highcharts.getOptions().colors[0]
                          }
                      },
                      labels: {
                          format: '{value:.0f}',
                          style: {
                              color: Highcharts.getOptions().colors[0]
                          }
                      },
                      opposite: true,
                      min: 0,
                      
                  },
                  { // Primary yAxis
                    allowDecimals: false,
                      labels: {
                          format: '{value:.0f} ',
                          style: {
                              color: Highcharts.getOptions().colors[1]
                          }
                      },
                      title: {
                          text: 'Profile Referral Count',
                          style: {
                              color: Highcharts.getOptions().colors[1]
                          }
                      },
                      // opposite: true,
                      min: 0
                  },
              ],
              tooltip: {
                  shared: true
              },
              legend: {
                align: 'center',
                layout: 'horizontal',
                verticalAlign: 'bottom',
                y: 20, // Adjust the y position as needed
                backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
            },
              series: [{
                      name: ' Guru Count',
                      type: 'spline',
                      data: this.prolifereferalguruCount,
                      color:'rgba(44,175,254,255)',
                      tooltip: {
                        shared: true,
                        pointFormatter: function () {
                          let value = this.y;
                          if (value >= 10000000) {
                            value = (value / 10000000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Cr';
                          } else if (value >= 100000) {
                            value = (value / 100000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Lakh';
                          } else {
                            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          }
                          return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + value + '</b><br/>';
                        }
                      },
                  },
                  {
                      name: 'Profile Referral Count',
                      type: 'column',
                      yAxis: 1,
                      data: this.prolifeReferalCount,
                      color: 'rgba(84,79,197,255)',
                      tooltip: {
                        shared: true,
                        pointFormatter: function () {
                          let value = this.y;
                          if (value >= 10000000) {
                            value = (value / 10000000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Cr';
                          } else if (value >= 100000) {
                            value = (value / 100000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Lakh';
                          } else {
                            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          }
                          return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + value + '</b><br/>';
                        }
                      },

                  },

              ]
          };
            Highcharts.chart(id, this.prolifereferalGraph)
          }
          else if (res.success === false && res.msg === "Data Not Found") {
            this.ngxLoader.stopLoader('loader-05');
            let id = document.getElementById('proliferefralgraph');
            id.innerHTML = "<div style='text-align: center;'>No Data Found</div>";
          }
          else {
            // this.prolifecountdata = [];
            this.prolifeReferalCount=[]
            this.prolifereferalguruCount=[]
            this.ngxLoader.stopLoader('loader-05');
          }
        },
      }))

    } catch (error) {
      this.ngxLoader.stopLoader('loader-05');
    }
  }

  formControlValue(req) {
    this.filterData = req;
    this.currentPage = 0;
    this.jobcard_statistics_monthly();
    this.vehical_Referal_Count();
    this.prolife_Referal_Count()
    // this.active_Job_Card();
    this.lassWise_Data('lass');
    this.lassWise_Data('Mechanical');
    this.partConsumptionData();
    this.active_Guru_data();
    this.doorstepBookingData();
    // this.vehical_Referal_Count()
  }


  activeGraph: any;
  active_Guru_data() {
    try {
      this.ngxLoader.startLoader('loader-04');
      this.activemonth=[];
      this.activeguruCount=[];
      let data = {
        "from_date": this.datepipe.transform(this.filterData ? this.filterData.fromDate : this.FromDate, 'yyyy-MM'),
        "to_date": this.datepipe.transform(this.filterData ? this.filterData.toDate : this.ToDate, 'yyyy-MM'),
        "lass_mobile": this.lassmobileno?this.lassmobileno:this.filterData ? this.filterData.lass_number:'',
        "lass_name":  this.lassname?this.lassname:this.filterData ? this.filterData.lass_name:'',
        "lob":  this.lob?this.lob:this.filterData ? this.filterData.lob:[],
        "ppl": this.ppl?this.ppl:this.filterData ? this.filterData.ppl:[],
        "pl":  this.pl?this.pl:this.filterData ? this.filterData.pl :[],
        "emission_norm": this.emission_norm?this.emission_norm:this.filterData ? this.filterData.emission_norm:[],
        "state_name": this.State?this.State:this.filterData ? this.filterData.state_name:[],
        "jobcard_count": this.jobcardcount?this.jobcardcount:0,
        "zone": this.role === 'RPM'||this.role === 'DEALER' ? this.zone : this.region ? this.region : '',
        "is_star_guru": this.starguru ? this.starguru: this.filterData? this.filterData.is_star_guru:[],
        // "service_type":  this.sr?this.sr:this.filterData ? this.filterData.service_type:[],
        "dealers": this.dealer 
          ? [this.dealer.toUpperCase()] 
          : this.filterData && Array.isArray(this.filterData.dealers) 
          ? this.filterData.dealers.map((d: string) => d.toUpperCase()) 
          : [],
      };

      this.sub.add(this.Dashboard.activeGurudata(data).subscribe({
        next: (res: any) => {
          if (res.success===true) {
            this.ngxLoader.stopLoader('loader-04');
            this.activegurudatacount = res.result;
            this.activegurudatacount.forEach(element => { 
              const dateParts = element.year_month.split('-');
              const monthName = this.datepipe.transform(new Date(dateParts[0], dateParts[1] - 1), 'MMM');
              const formattedDate = `${monthName}-${dateParts[0]}`;
              this.activemonth.push(formattedDate);
              this.activeguruCount.push(element.guru_count);
            });
           

            // Create the chart
            // Create the chart
let id = document.getElementById('ActiveJobdata');
this.activeGraph = {
    chart: {
        type: 'column'
    },
    title: {
        text: null,
        align: 'left'
    },
    credits: {
        enabled: false
    },
    xAxis: {
        categories: this.activemonth, // Use the month names as categories
        title: {
            text: null
        }
    },
    yAxis: {
        title: {
            text: 'Count'
        }
    },
    tooltip: {
        valueSuffix: ' '
    },
    plotOptions: {
        series: {
            pointWidth: 50, // Adjust the width as needed
            grouping: true,
            shadow: false,
            borderWidth: 0,
            // borderRadius: '10%',
            borderRadiusTopLeft: '50%', // Set the upper border radius
            borderRadiusTopRight: '50%', // Set the upper border radius
        }
    },
    series: [{
        name: 'Count',
        data: this.activeguruCount ,// Use the count values as data points
        color:'rgba(44,175,254,255)',
        tooltip: {
          shared: true,
          pointFormatter: function () {
            let value = this.y;
            if (value >= 10000000) {
              value = (value / 10000000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Cr';
            } else if (value >= 100000) {
              value = (value / 100000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Lakh';
            } else {
              value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + value + '</b><br/>';
          }
        },
    }]
};
Highcharts.chart(id, this.activeGraph);

          }
          else if (res.success === false && res.result === "Data Not Found") {
            this.ngxLoader.stopLoader('loader-04');
            let id = document.getElementById('ActiveJobdata');
            id.innerHTML = "<div style='text-align: center;'>No Data Found</div>";
          }
          else {
            this.activeJobCardData = [];
            this.ngxLoader.stopLoader('loader-04');
          }
        }
      }));
    } catch {
      this.ngxLoader.stopLoader('loader-04');
     }
  }
 

 

  
  disableToaster() {
    setTimeout(() => {
      this.showToastermessage = false;
    }, 4000);
  }
  lassWise_Data(flag) {
    try {
      if(flag == 'lass'){
        this.ngxLoader.startLoader('loader-07');
      }
      else{
        this.ngxLoader.startLoader('loader-08');
      }
      // if (this.state && this.filterData?.zone) {
      //   this.showfinalErrorMsg = "";
      //   this.showfinalSuccessMsg = "Please select either state or zone";
      //   this.showToastermessage = true;
      //   this.disableToaster();
      //   return;
      // }
      let data = {
        "user": flag,
        "phone_no": this.Phoneno,
        "from_date": this.filterData ? this.filterData.fromDate : this.startDate,
        "to_date": this.filterData ? this.filterData.toDate : this.currentDateTime,
        // "region": this.filterData ? this.filterData.zone : "",
        "state":this.state?this.state: this.filterData ? this.filterData.state_name:[],
        "is_star_guru": this.starguru ? this.starguru: this.filterData? this.filterData.is_star_guru:[],
        // "service_type":  this.sr?this.sr:this.filterData ? this.filterData.service_type:[],
        // "dealers":  this.dealer?this.dealer:this.filterData ? this.filterData.dealers:[],
      }
      this.sub.add(this.Dashboard.lassWiseData(data).subscribe({
        next: (res: any) => {
          if (res.success===true) {
            if (data.user === "lass") {
              this.lassWiseData = res
              this.ngxLoader.stopLoader('loader-07');

              
            } else {
              this.mechanicalWiseData = res
              this.ngxLoader.stopLoader('loader-08');
            }
          }else{
              if (data.user === "lass") {
                this.lassWiseData = []
                this.ngxLoader.stopLoader('loader-07');
              }
            else{
              this.mechanicalWiseData=[]
              this.ngxLoader.stopLoader('loader-08');
            }
          }
        }
      }))
    } catch {
      this.ngxLoader.stopLoader('loader-07');
      this.ngxLoader.stopLoader('loader-08');
    }
  }
  partsConsGraph: any

partConsumptionData() {
  try {
    this.ngxLoader.startLoader('loader-03');
      this.yearmonth = [];
      this.amount = [];
      this.guruC = [];
      let data = {
          // "action_type":"lass_name",
          "from_date": this.filterData ? this.filterData.fromDate : this.FromDate,
          "to_date": this.filterData ? this.filterData.toDate : this.ToDate,
          "lass_mobile_no":  this.lassmobileno ? this.lassmobileno :this.filterData ? this.filterData.lass_number : '',
          "lass_name":  this.lassname ? this.lassname : this.filterData ? this.filterData.lass_name :'',
          "lob": this.lob ? this.lob : this.filterData ? this.filterData.lob : [],
          "ppl":  this.ppl ? this.ppl :this.filterData ? this.filterData.ppl : [],
          "pl":  this.pl ? this.pl :this.filterData ? this.filterData.pl : [],
          "emission_norm": this.emission_norm ? this.emission_norm : this.filterData ? this.filterData.emission_norm : [],
          "state":  this.State ? this.State : this.filterData ? this.filterData.state_name :[],
          "zone": this.role === 'RPM'||this.role === 'DEALER' ? this.zone : this.region ? this.region : '',
          "is_star_guru": this.starguru ? this.starguru: this.filterData? this.filterData.is_star_guru:[],
          "service_type":  this.sr?this.sr:this.filterData ? this.filterData.service_type:[],
          "dealers": this.dealer 
          ? [this.dealer.toUpperCase()] 
          : this.filterData && Array.isArray(this.filterData.dealers) 
          ? this.filterData.dealers.map((d: string) => d.toUpperCase()) 
          : [],

      }
      this.sub.add(this.Dashboard.partsConsumptions(data).subscribe({
          next: (res: any) => {
              if (res.sucess===true) {
                this.ngxLoader.stopLoader('loader-03');
                  this.partsconsumptionData = res.data;
                  
                  let monthDataMap = {};
                  this.partsconsumptionData.forEach(element => {
                    monthDataMap[element.year_month] = {
                      amount: element.amount,
                      guru_count: element.guru_count
                    };
                    this.yearmonth.push(element.year_month);
                  });

                  this.yearmonth.sort((a, b) => {
                    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                    return months.indexOf(a.split(' ')[0]) - months.indexOf(b.split(' ')[0]);
                  });
        
                  this.yearmonth.forEach(month => {
                    let dataPoint = monthDataMap[month];
                    if (dataPoint) {
                      this.amount.push(dataPoint.amount);
                      this.guruC.push(dataPoint.guru_count);
                    } else {
                      this.amount.push(0);
                      this.guruC.push(0);
                    }
                  });
        







                //   this.partsconsumptionData.forEach(element => {
                //     this.yearmonth.push(element.year_month);
                //     this.amount.push(element.amount);
                //     this.guruC.push(element.guru_count);
                // });
                
               
                  // this.partsconsumptionData.forEach(element => {
                  //     this.yearmonth.push(element['year_&_month']);
                  //     this.amount.push(element.amount);
                  //     this.guruC.push(element.guru_count);
                  // });

                  
              
                  let id = document.getElementById('partsconsgraph');
                  // Find the maximum value in the amount array
                  let maxAmount = Math.max(...this.amount, ...this.guruC);

                  // Calculate a suitable ceiling value (e.g., rounding up to the nearest 100)
                  let ceilingValue = Math.ceil(maxAmount / 100) * 100;
                  this.partsConsGraph = {
                      chart: {
                          zoomType: 'xy'
                      },
                      title: {
                          text: null,
                          align: 'left'
                      },
                      subtitle: {

                          align: 'left'
                      },
                      plotOptions: {
                          column: {
                              pointWidth: 50, // Adjust the width as needed
                              grouping: true,
                              shadow: false,
                              borderWidth: 0
                          }
                      },
                      credits: {
                          enabled: false
                      },
                      xAxis: [{
                          categories: this.yearmonth,
                          crosshair: true
                      }],
                      yAxis: [{ // Secondary yAxis
                              title: {
                                  text: 'Guru Count ',
                                  style: {
                                      color: Highcharts.getOptions().colors[0]
                                  }
                              },
                              labels: {
                                  format: '{value:.0f}',
                                  style: {
                                      color: Highcharts.getOptions().colors[0]
                                  }
                              },
                              opposite: true,
                              min: 0 
                          },
                          { // Primary yAxis
                              labels: {
                                  format: '',
                                  style: {
                                      color: Highcharts.getOptions().colors[1]
                                  }
                              },
                              title: {
                                  text: 'Amount',
                                  style: {
                                      color: Highcharts.getOptions().colors[1]
                                  },
                                  
                              }
                          },
                      ],
                      tooltip: {
                          shared: true
                      },
                      legend: {
                        align: 'center',
                        layout: 'horizontal',
                        verticalAlign: 'bottom',
                        y: 20, // Adjust the y position as needed
                        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
                    },
                      series: [{
                              name: 'Guru Count',
                              type: 'spline',
                              data: this.guruC,
                              color:'rgba(44,175,254,255)',
                              tooltip: {
                                shared: true,
                                pointFormatter: function () {
                                  let value = this.y;
                                  if (value >= 10000000) {
                                    value = (value / 10000000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Cr';
                                  } else if (value >= 100000) {
                                    value = (value / 100000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Lakh';
                                  } else {
                                    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                  }
                                  return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + value + '</b><br/>';
                                }
                              },
                          },
                          {
                              name: 'Amount',
                              type: 'column',
                              yAxis: 1,
                              data: this.amount ,
                              color: 'rgba(84,79,197,255)',
                              tooltip: {
                                shared: true,
                                pointFormatter: function () {
                                  let value = this.y;
                                  if (value >= 10000000) {
                                    value = (value / 10000000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Cr';
                                  } else if (value >= 100000) {
                                    value = (value / 100000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Lakh';
                                  } else {
                                    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                  }
                                  return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + value + '</b><br/>';
                                }
                              },

                          },

                      ]
                  };

                  Highcharts.chart(id, this.partsConsGraph)
              } else if (res.sucess === false && res.data === "Data Not Found") {
                this.ngxLoader.stopLoader('loader-03');
                  let id = document.getElementById('partsconsgraph');
                  id.innerHTML = "<div style='text-align: center;'>No Data Found</div>";
              } else {
                  this.partsconsumptionData = []
                  this.ngxLoader.stopLoader('loader-03');
              }


          }
      }))
  } catch {
    this.ngxLoader.stopLoader('loader-03');
  }
}
  doorstepopencard: any;
  doorstepclosecard: any;
  doorstepmounth: any;
  doorstepgraph: any;
  doorstepBookingData() {
    try {
      this.ngxLoader.startLoader('loader-02'); // Start the loader
      this.doorstepopencard = [];
      this.doorstepclosecard = [];
      this.doorstepmounth = [];
      let data = {
        "from_date": this.filterData ? this.filterData.fromDate : this.FromDate,
        "to_date": this.filterData ? this.filterData.toDate : this.ToDate,
        "problem_name": this.problemname ? this.problemname : this.filterData ? this.filterData.problem_name : [],
        "lass_number":  this.lassmobileno ? this.lassmobileno :this.filterData ? this.filterData.lass_number : [],
        "lass_name": this.lassname ? this.lassname :  this.filterData ? this.filterData.lass_name :[],
        "lob": this.lob ? this.lob : this.filterData ? this.filterData.lob : [],
        "ppl":  this.ppl ? this.ppl :this.filterData ? this.filterData.ppl : [],
        "pl":  this.pl ? this.pl : this.filterData ? this.filterData.pl :[],
        "emission_norm": this.emission_norm ? this.emission_norm :  this.filterData ? this.filterData.emission_norm :[],
        "state_name":  this.State ? this.State :this.filterData ? this.filterData.state_name : [],
        "zone": this.role === 'RPM'||this.role === 'DEALER' ? this.zone : this.region ? this.region : '',
        "is_star_guru": this.starguru ? this.starguru: this.filterData? this.filterData.is_star_guru:[],
        "service_type":  this.sr?this.sr:this.filterData ? this.filterData.service_type:[],
        "dealers": this.dealer 
          ? [this.dealer.toUpperCase()] 
          : this.filterData && Array.isArray(this.filterData.dealers) 
          ? this.filterData.dealers.map((d: string) => d.toUpperCase()) 
          : [],
      }
  
      // if (this.role === 'SuperAdmin') {
      //   data = {
      //     ...data,
      //     dealers: this.dealer ? this.dealer : this.filterData ? this.filterData.dealers : []
      //   };
      // }
    
      this.sub.add(this.Dashboard.doorStepBooking(data).subscribe({
        next: (res: any) => {
          if (res.success === true) {
            this.ngxLoader.stopLoader('loader-02'); // Stop the loader
            this.doorstepbooking = res.result
            this.doorstepbooking.sort((a, b) => {
              const aMonth = a.year_month.split(' ')[0];
              const bMonth = b.year_month.split(' ')[0];
              const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              return months.indexOf(aMonth) - months.indexOf(bMonth);
          });
          
          this.doorstepbooking.forEach(element => {
              this.doorstepopencard.push(element.total_jobcard_count);
              this.doorstepclosecard.push(element.total_closed_jobcard_count);
              this.doorstepmounth.push(element.year_month);
          });
       
  
            let id = document.getElementById('DoorstepBooking');
            let maxOpenCard = Math.max(...this.doorstepopencard);
            let maxCloseCard = Math.max(...this.doorstepclosecard);
            let maxYAxis = Math.max(maxOpenCard, maxCloseCard);
  
            this.doorstepgraph = {
              chart: {
                type: 'column',
              },
              title: {
                text: null,
                align: 'left'
              },
              xAxis: {
                categories:this.doorstepmounth,
                crosshair: true,
                accessibility: {
                  description: 'Month & year'
                }
              },
              yAxis: {
                min: 0,
                max: maxYAxis,
                ceiling: maxYAxis,
                title: {
                  text: ''
                }
              },
              credits: {
                enabled: false
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0
                }
              },
              series: [
                // {
                //   name: 'Total Jobcard Count',
                //   data: this.doorstepopencard,
                //   color: 'rgba(44,175,254,255)',
                //   tooltip: {
                //     shared: true,
                //     pointFormatter: function () {
                //       let value = this.y;
                //       if (value >= 10000000) {
                //         value = (value / 10000000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Cr';
                //       } else if (value >= 100000) {
                //         value = (value / 100000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Lakh';
                //       } else {
                //         value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //       }
                //       return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + value + '</b><br/>';
                //     }
                //   },
                // },
                {
                  name: 'Total Closed Jobcard Count ',
                  data: this.doorstepclosecard,
                  color: 'rgba(84,79,197,255)',
                  tooltip: {
                    shared: true,
                    pointFormatter: function () {
                      let value = this.y;
                      if (value >= 10000000) {
                        value = (value / 10000000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Cr';
                      } else if (value >= 100000) {
                        value = (value / 100000).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') + ' Lakh';
                      } else {
                        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }
                      return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + value + '</b><br/>';
                    }
                  },
                },
              ]
            };
  
            Highcharts.chart(id, this.doorstepgraph)
  
          } else if (res.success === false && res.result === "Data Not Found") {
            this.ngxLoader.stopLoader('loader-02');
            let id = document.getElementById('DoorstepBooking');
            id.innerHTML = "<div style='text-align: center;'>No Data Found</div>";
          } else {
            this.doorstepbooking = []
            this.ngxLoader.stopLoader('loader-02');
          }
        }
      }))
    } catch {
      this.ngxLoader.stopLoader('loader-02');
    }
  }
  





  dropdown(flag) {
 
    let stateval=this.doorstepfilter.controls['state_name']?.value
  let lassval=this.doorstepfilter.controls['lass_name']?.value 
  



   
    try {

      let data = {
        "action_type":flag,
        "state_name": [],
        "lass_name":''
      };
      
      if(flag=='lass_name'){
data.state_name=stateval
data.lass_name=lassval
      }

      this.sub.add(this.Dashboard.dropdowndata(data).subscribe({
        next: (res: any) => {
          if (res.success === true) {
            if (flag == 'lob') {
              this.lobData = res.result;
            } else if (flag == 'ppl') {
              this.ppldata = res.result;
            } else if (flag == 'pl') {
              this.pldata = res.result;
            } else if (flag == 'emission_norm') {
              this.emissionormdata = res.result;
            } else if (flag == 'problem_name') {
              this.ProblemData = res.result;
            } else if (flag == 'state') {
              this.states = res.result;
            } else if (flag == 'lass_name') {
              this.lassName = res.result;
            }
            else if (flag == 'region') {
              this.Region = res.result;
            }
            else if (flag == 'starguru') {
              this.starguru = res.result;
            }
            else if (flag == 'sr') {
              this.sr = res.result;
            }
          } else {
            // Make state_name and lass_name arrays empty if res.success is false
            // this.states = [];
            this.lassName = [];
          }
        }
      }));
    } catch (error) {
      console.log(error);
    }
  }

  patrssconsss(flag) {
  

    //code
    let stateval= this.partsFilter.controls['state']?.value
  let lassval=this.partsFilter.controls['lass_name'].value 
  




   
    try {

      let data = {
        "action_type":flag,
        "state_name": [],
        "lass_name":''
      };
      
      if(flag=='lass_name'){
data.state_name=stateval
data.lass_name=lassval
      }

      this.sub.add(this.Dashboard.dropdowndata(data).subscribe({
        next: (res: any) => {
          if (res.success === true) {
            if (flag == 'lob') {
              this.lobData = res.result;
            } else if (flag == 'ppl') {
              this.ppldata = res.result;
            } else if (flag == 'pl') {
              this.pldata = res.result;
            } else if (flag == 'emission_norm') {
              this.emissionormdata = res.result;
            } else if (flag == 'problem_name') {
              this.ProblemData = res.result;
            } else if (flag == 'state') {
              this.states = res.result;
            } else if (flag == 'lass_name') {
              this.lassName = res.result;
            }
            else if (flag == 'region') {
              this.Region = res.result;
            }
            else if (flag == 'starguru') {
              this.starguru = res.result;
            }
            else if (flag == 'sr') {
              this.sr = res.result;
            }
          } else {
            // Make state_name and lass_name arrays empty if res.success is false
            // this.states = [];
            this.lassName = [];
          }
        }
      }));
    } catch (error) {
      console.log(error);
    }
  }

  Activedrop(flag) {
  
    //code
    let stateval=this.activejobFilter.controls['state']?.value
  let lassval=this.activejobFilter.controls['lass_name'].value 
  

   
    try {

      let data = {
        "action_type":flag,
        "state_name": [],
        "lass_name":''
      };
      
      if(flag=='lass_name'){
data.state_name=stateval
data.lass_name=lassval
      }

      this.sub.add(this.Dashboard.dropdowndata(data).subscribe({
        next: (res: any) => {
          if (res.success === true) {
            if (flag == 'lob') {
              this.lobData = res.result;
            } else if (flag == 'ppl') {
              this.ppldata = res.result;
            } else if (flag == 'pl') {
              this.pldata = res.result;
            } else if (flag == 'emission_norm') {
              this.emissionormdata = res.result;
            } else if (flag == 'problem_name') {
              this.ProblemData = res.result;
            } else if (flag == 'state') {
              this.states = res.result;
            } else if (flag == 'lass_name') {
              this.lassName = res.result;
            }
            else if (flag == 'region') {
              this.Region = res.result;
            }
            else if (flag == 'starguru') {
              this.starguru = res.result;
            }
            else if (flag == 'sr') {
              this.sr = res.result;
            }
          } else {
            // Make state_name and lass_name arrays empty if res.success is false
            // this.states = [];
            this.lassName = [];
          }
        }
      }));
    } catch (error) {
      console.log(error);
    }
  }


  dropdownjob(flag) {
   
    //code
    let stateval=this.jobstaticsfilter.controls['state_name']?.value
  let lassval=this.jobstaticsfilter.controls['lass_name']?.value 

    try {

      let data = {
        "action_type":flag,
        "state_name": [],
        "lass_name":''
      };
      
      if(flag=='lass_name'){
data.state_name=stateval
data.lass_name=lassval
      }

      this.sub.add(this.Dashboard.dropdowndata(data).subscribe({
        next: (res: any) => {
          if (res.success === true) {
            if (flag == 'lob') {
              this.lobData = res.result;
            } else if (flag == 'ppl') {
              this.ppldata = res.result;
            } else if (flag == 'pl') {
              this.pldata = res.result;
            } else if (flag == 'emission_norm') {
              this.emissionormdata = res.result;
            } else if (flag == 'problem_name') {
              this.ProblemData = res.result;
            } else if (flag == 'state') {
              this.states = res.result;
            } else if (flag == 'lass_name') {
              this.lassName = res.result;
            }
            else if (flag == 'region') {
              this.Region = res.result;
            }
            else if (flag == 'starguru') {
              this.starguru = res.result;
            }
            else if (flag == 'sr') {
              this.sr = res.result;
            }
          } else {
            // Make state_name and lass_name arrays empty if res.success is false
            // this.states = [];
            this.lassName = [];
          }
        }
      }));
    } catch (error) {
      console.log(error);
    }
  }
 
  
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

