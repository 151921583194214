<div class="tb-table-wrapper">
  <div class="guru-tabs-row d-flex" style="justify-content: end;">
      <div class="guru-tabs-items">
          <div class="tb-btn-group">
              <ul>
                <li *ngIf="!isAddRPM">
                  <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="refresh()">
                      Refresh
                  </button>
              </li>
                <li *ngIf="!isAddRPM">
                  <div class="tb-filter-wrapper">
                      <button mat-stroked-button class="primary-stroke-button filterBtn" (click)="openDropdown()" [ngClass]="{ open: isOpenDropdown }">
                      Filter
                      </button>
                      <div class="custom-filter-sec" *ngIf="isOpenDropdown">
                      <h3>
                          Filter <button class="filterCloseBtn" (click)="closeDropdown()"></button>
                      </h3>
                      <form [formGroup]="rpmfilterForm" name="rpmfilterForm " class="form">
                              <div class="custom-filter-body" style="justify-content: space-evenly;">
                              </div>
                              <div class="custom-filter-body" style="justify-content: space-evenly;">
                                <div class="custom-filter-col">
                                      <mat-form-field>
                                          <mat-label>Mobile Number</mat-label>
                                          <input formControlName="mobileNumber" matInput (keydown.enter)="applyFilter()"maxlength="10"
                                          (keypress)="keyPressNumbers($event)">
                                      </mat-form-field>
                                  </div>
                                  <div class="custom-filter-col"></div>
                              </div>
                              <div class="btn-group filterBtn-group">
                                  <button mat-stroked-button class="primary-stroke-button"(click)="reset()" >Reset</button>
                                  <button mat-flat-button class="primary-flat-button" (click)="applyFilter()">Apply</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </li>
                  <li>
                      <div class="addUser-btn">
                          <button mat-flat-button matStepperNext class="primary-flat-button" 
                          (click)="isAddRPM = !isAddRPM">
                               {{isAddRPM ? 'Back' : 'Add RPM'}}
                          </button>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</div>

<div class="form-wrap comn-box add-item-content" *ngIf="isAddRPM">
  <form [formGroup]="addRPMForm" (ngSubmit)="addRPMFormData()" autocomplete="off">
      <div class="form-section">
          <!-- <div class="form-row">
              <div class="form-col">
                  <label for="contractor" class="form-label">Contractor <span class="requiredfield">*</span></label>
                  <mat-radio-group aria-label="Select an option" formControlName="is_contractor">
                      <mat-radio-button value="1">Yes</mat-radio-button>
                      &nbsp;
                      <mat-radio-button value="2"  [checked]="true">No</mat-radio-button>
                    </mat-radio-group>
              </div>
              <div class="form-col">
                  <label for="active" class="form-label">Active <span class="requiredfield">*</span></label>
                  <mat-slide-toggle formControlName="is_active"></mat-slide-toggle>
                </div>
          </div> -->
          <div class="form-row">
              <div class="form-col">
                  <label for="firstName" class="form-label">First Name <span class="requiredfield">*</span></label>
                  <input type="text" class="form-control" id="firstName" formControlName="firstName" maxlength="30"
                      placeholder="Enter First Name" appOnlyAlphabet />
                  <div *ngIf="submitted && this.addRPMForm.controls.firstName.errors" class="invalid-field">
                      <span *ngIf="this.addRPMForm.controls.firstName.errors.required">First Name is required</span>
                      <span *ngIf="this.addRPMForm.controls.firstName.errors.pattern">Please enter valid First
                          Name</span>
                      <span *ngIf="
                !this.addRPMForm.controls.firstName.errors.required &&
                this.addRPMForm.controls.firstName.errors.whitespace
              ">Please enter valid data</span>
                  </div>
              </div>
              <div class="form-col">
                  <label for="lastName" class="form-label">Last Name <span class="requiredfield">*</span></label>
                  <input type="text" class="form-control" id="lastName" formControlName="lastName" maxlength="30"
                      placeholder="Enter Last Name" appOnlyAlphabet />
                  <div *ngIf="submitted && this.addRPMForm.controls.lastName.errors" class="invalid-field">
                      <span *ngIf="this.addRPMForm.controls.lastName.errors.required">Last Name is required</span>
                      <span *ngIf="this.addRPMForm.controls.lastName.errors.pattern">Please enter valid Last
                          Name</span>
                      <span *ngIf="
                !this.addRPMForm.controls.lastName.errors.required &&
                this.addRPMForm.controls.lastName.errors.whitespace
              ">Please enter valid data</span>
                  </div>
              </div>
          </div>
          <div class="form-row">
              <div class="form-col">
                  <label for="mobileNum" class="form-label">Mobile Number <span class="requiredfield">*</span></label>
                  <input type="text" class="form-control" id="mobileNum" (keypress)="keyPressNumbers($event)"
                      placeholder="Enter Mobile Number" formControlName="mobileNum" maxlength="10" />
                  <div *ngIf="submitted && this.addRPMForm.controls.mobileNum.errors" class="invalid-field">
                      <span *ngIf="this.addRPMForm.controls.mobileNum.errors.required">Mobile Number is
                          required</span>
                      <span *ngIf="
                this.addRPMForm.controls.mobileNum.errors.pattern ||
                this.addRPMForm.controls.mobileNum.errors.min
              ">Please enter valid Mobile Number</span>
                  </div>
              </div>
              <div class="form-col">
                  <label for="username" class="form-label">Username <span class="requiredfield">*</span></label>
                  <input type="text" class="form-control" id="username" formControlName="username"
                      (keypress)="removeSpace()" placeholder="Enter TML User Name : Exp. SSINGH1000930" />
                  <div *ngIf="submitted && this.addRPMForm.controls.username.errors" class="invalid-field">
                      <span *ngIf="this.addRPMForm.controls.username.errors.required">User Name is required</span>
                      <span *ngIf="this.addRPMForm.controls.username.errors.pattern">Please enter valid User
                          Name</span>
                      <span *ngIf="
                !this.addRPMForm.controls.username.errors.required &&
                this.addRPMForm.controls.username.errors.whitespace
              ">Please enter valid data</span>
                  </div>
              </div>
          </div>
          <div class="form-row">
              <div class="form-col">
                  <label for="state" class="form-label">State<span class="requiredfield">*</span></label>
                  <mat-form-field>
                      <mat-label label for="state" class="form-label"> Select State <span class="requiredfield">*</span> </mat-label>
                      <mat-select formControlName="state" >
                          <mat-option [value]="state" *ngFor="let state of states ">{{ state }}</mat-option>
                      </mat-select>
                  </mat-form-field>
                  <div *ngIf="submitted && addRPMForm.get('state').invalid" class="invalid-field">
                      <span>Please select a State</span>
                  </div>
                  <div *ngIf="submitted && addRPMForm.get('state').invalid && addRPMForm.get('state').touched" class="invalid-field">
                      <span>Please select a State</span>
                  </div>
              </div>
              <div class="form-col">
                  <label for="region" class="form-label">Region<span class="requiredfield">*</span></label>
                  <mat-form-field>
                      <mat-label label for="state" class="form-label"> Select region <span class="requiredfield">*</span></mat-label>
                      <mat-select formControlName="region" >
                          <mat-option [value]="region" *ngFor="let region of regions ">{{ region }}</mat-option>
                      </mat-select>
                  </mat-form-field>
                  <div *ngIf="submitted && addRPMForm.get('region').invalid && addRPMForm.get('region').touched" class="invalid-field">
                      <span>Please select a Region</span>
                  </div>
                  <div *ngIf="submitted && addRPMForm.get('region').invalid" class="invalid-field">
                      <span>Please select a Region</span>
                  </div>
              </div>
          </div>
          

          <div class="form-row">
<div class="form-col">
  <label for="email" class="form-label">Email Id  </label>
  <input type="text" class="form-control" id="email"  placeholder="Enter TML Email Id" formControlName="email" (keypress)="removeSpace()" />
  <div *ngIf="submitted && this.addRPMForm.controls.email.errors" class="invalid-field">
    <!-- <span *ngIf="this.addRPMForm.controls.email.errors.required">Email Id is required</span>
                        <span *ngIf="this.addRPMForm.controls.email.errors.pattern">Please enter valid Email Id</span> -->
  </div>
</div>

              
          </div>
      </div>
      <div class="addUser-btn">
          <button mat-flat-button matStepperNext class="primary-flat-button" type="submit">
              Add 
          </button>
      </div>
  </form>
</div>
<!-- <div class="form-wrap comn-box add-item-content" *ngIf="isAddRPM">
  <form
    [formGroup]="addRPMForm"
    (ngSubmit)="addRPMFormData()"
    autocomplete="off"
  >
    <div class="form-section">
      <div class="form-row">
        <div class="form-col">
         
          <label for="region" class="form-label"
            >Select Region <span class="requiredfield">*</span></label
          >
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="form-select"
          >
            <mat-select
              [formControl]="websiteMultiCtrl"
              placeholder="Please Select Region"
              [multiple]="true"
              #multiSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="websiteMultiFilterCtrl"
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No record found"
                ></ngx-mat-select-search>
              </mat-option>

              <mat-option
                *ngFor="let website of filteredWebsitesMulti | async"
                [value]="website"
              >
                {{ website }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div
            *ngIf="submitted && this.websiteMultiCtrl.invalid"
            class="invalid-field"
          >
            <span>Please select Region</span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-col">
          <label for="firstName" class="form-label"
            >First Name <span class="requiredfield">*</span></label
          >
          <input
            type="text"
            class="form-control"
            id="firstName"
            formControlName="firstName"
            maxlength="30"
            appOnlyAlphabet
          />
          <div
            *ngIf="submitted && this.addRPMForm.controls.firstName.errors"
            class="invalid-field"
          >
            <span *ngIf="this.addRPMForm.controls.firstName.errors.required"
              >First Name is required</span
            >
            <span *ngIf="this.addRPMForm.controls.firstName.errors.pattern"
              >Please enter valid First Name</span
            >
            <span
              *ngIf="
                !this.addRPMForm.controls.firstName.errors.required &&
                this.addRPMForm.controls.firstName.errors.whitespace
              "
              >Please enter valid data</span
            >
          </div>
        </div>
        <div class="form-col">
          <label for="lastName" class="form-label"
            >Last Name <span class="requiredfield">*</span></label
          >
          <input
            type="text"
            class="form-control"
            id="lastName"
            formControlName="lastName"
            maxlength="30"
            appOnlyAlphabet
          />
          <div
            *ngIf="submitted && this.addRPMForm.controls.lastName.errors"
            class="invalid-field"
          >
            <span *ngIf="this.addRPMForm.controls.lastName.errors.required"
              >Last Name is required</span
            >
            <span *ngIf="this.addRPMForm.controls.lastName.errors.pattern"
              >Please enter valid Last Name</span
            >
            <span
              *ngIf="
                !this.addRPMForm.controls.lastName.errors.required &&
                this.addRPMForm.controls.lastName.errors.whitespace
              "
              >Please enter valid data</span
            >
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label for="mobileNum" class="form-label"
            >Mobile Number <span class="requiredfield">*</span></label
          >
          <input
            type="text"
            class="form-control"
            id="mobileNum"
            (keypress)="keyPressNumbers($event)"
            formControlName="mobileNum"
            maxlength="10"
          />
          <div
            *ngIf="submitted && this.addRPMForm.controls.mobileNum.errors"
            class="invalid-field"
          >
            <span *ngIf="this.addRPMForm.controls.mobileNum.errors.required"
              >Mobile Number is required</span
            >
            <span
              *ngIf="
                this.addRPMForm.controls.mobileNum.errors.pattern ||
                this.addRPMForm.controls.mobileNum.errors.min
              "
              >Please enter valid Mobile Number</span
            >
          </div>
        </div>
        <div class="form-col">
          <label for="email" class="form-label"
            >Email Id <span class="requiredfield">*</span></label
          >
          <input
            type="text"
            class="form-control"
            id="email"
            formControlName="email"
            (keypress)="removeSpace()"
          />
          <div
            *ngIf="submitted && this.addRPMForm.controls.email.errors"
            class="invalid-field"
          >
            <span *ngIf="this.addRPMForm.controls.email.errors.required"
              >Email Id is required</span
            >
            <span *ngIf="this.addRPMForm.controls.email.errors.pattern"
              >Please enter valid Email Id</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="addUser-btn">
      <button
        mat-flat-button
        matStepperNext
        class="primary-flat-button"
        type="submit"
      >
        Add User
      </button>
    </div>
  </form>
</div> -->

<div class="table-wrapper" *ngIf="!isAddRPM">
  <div class="guru-list-table rpm-table" *ngIf="!showNodata">
      <table
       mat-table matTableExporter class="full-width-table tb-table" matSort aria-label="Elements"
          [dataSource]="dataSource"
          >
          <!-- <ng-container matColumnDef="username">
              <th class="" mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">
                  Username
              </th>
              <td class="" mat-cell *matCellDef="let row">
                  {{ row.username }}
              </td>
          </ng-container> -->
          <ng-container matColumnDef="firstName">
              <th class="" mat-header-cell *matHeaderCellDef style="width: 10%;">First Name</th>
              <td class="" mat-cell *matCellDef="let row">
                  {{ row.first_name}}
              </td>
          </ng-container>
          <ng-container matColumnDef="lastName">
              <th class="" mat-header-cell *matHeaderCellDef style="width: 10%;">Last Name</th>
              <td class="" mat-cell *matCellDef="let row">
                  {{ row.last_name}}
              </td>
          </ng-container>
          
          <!-- <ng-container matColumnDef="email">
              <th class="" mat-header-cell *matHeaderCellDef style="width: 15%;">Email</th>
              <td class="" mat-cell *matCellDef="let row">
                  {{ row.email || "-"}}
              </td>
          </ng-container> -->
          <!-- <ng-container matColumnDef="dateJoined">
              <th class="" mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">
                  Joining Date
              </th>
              <td class="" mat-cell *matCellDef="let row">
                  {{ row.date_joined | date: 'dd/MM/yyyy' }}
              </td>
          </ng-container> -->
          <ng-container matColumnDef="Region">
              <th class="" mat-header-cell *matHeaderCellDef style="width: 10%;">Region</th>
              <td class="" mat-cell *matCellDef="let row">
                  {{ row.region }}
              </td>
          </ng-container>
          <ng-container matColumnDef="phoneNumber">
              <th class="" mat-header-cell *matHeaderCellDef style="width: 10%;">Contact</th>
              <td class="" mat-cell *matCellDef="let row">
                  {{ row.phone_number }}
              </td>
          </ng-container>

          <ng-container matColumnDef="status">
              <th class="status-col" mat-header-cell *matHeaderCellDef style="width: 20%;">Status</th>
              <td class="status-col" mat-cell *matCellDef="let row">
                <mat-slide-toggle
                  class="example-margin"
                  [(ngModel)]="row.is_active"
                  (change)="toggleLasStatus($event.checked, row)">
                  {{ row.is_active ? "Active" : "Inactive" }}
                </mat-slide-toggle>
              </td>
            </ng-container>

          <tr class="tb-head-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="tb-item-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
  </div>

  <ng-container *ngIf="showNodata">
      <div class="no-item-found">No record found</div>
  </ng-container>

  <mat-paginator
    appPagination
    showFirstLastButtons
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [hidden]="showNodata"
    (page)="pageChanged($event)"
    class="custom-paginator"
    [hidePageSize]="true"
  >
  </mat-paginator>
</div>

<!-- <div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
  </div>
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
  </div>
</div> -->

<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
  </div>
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
  </div>
</div>
