import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-otp-generate",
  templateUrl: "./otp-generate.component.html",
  styleUrls: ["./otp-generate.component.scss"],
})
export class OtpGenerateComponent implements OnInit {
  getOTPform: any;
  fillOtp: boolean = true;

  constructor(private _formBuilder: FormBuilder, private router: Router) {
    this.getOTPform = this._formBuilder.group({
      otp1: ["", [Validators.required]],
      otp2: ["", [Validators.required]],
      otp3: ["", [Validators.required]],
      otp4: ["", [Validators.required]],
    });
  }

  ValidateOTP() {
  }

  onKeyUp(event) {
    let element;
    if (event.code !== "Backspace")
      element = event.srcElement.nextElementSibling;

    if (event.code === "Backspace")
      element = event.srcElement.previousElementSibling;

    if (element == null) return;
    else element.focus();

    if (this.getOTPform.valid) {
      this.fillOtp = false;
    }
  }

  ngOnInit(): void {}
}
