import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { ApiService } from "src/app/services/api.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { HttpMethod } from "src/app/constants/http-handlers";

@Component({
  selector: "app-targets",
  templateUrl: "./targets.component.html",
  styleUrls: ["./targets.component.scss"],
})
export class TargetsComponent implements OnInit {
  msrGuruTargetTemplateURL: string;
  role: string;
  selectedFile: any;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  currentMonthYear: any;
  nextMonthYear: any;
  currentMonthYearVal: any;
  nextMonthYearVal: any;
  enableGuruTargetTemplateBtn: boolean = false;
  dropval: object;
  dropdownVal: any;

  showNodata: boolean = true;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource = new MatTableDataSource<any>();

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  displayedColumns = [
    "guru_id",
    "guru_name",
    "job_card",
    "part_Consumed",
    "success",
    "failure_reason",
  ];

  displayedColumns2 = [
    "msr_id",
    "msr_name",
    "guru_registered",
    "job_card",
    "part_Consumed",
    "success",
    "failure_reason",
  ];

  constructor(
    private _snackBar: MatSnackBar,
    private commonService: CommonService,
    private appService: AppService,
    private apiService: ApiService
  ) {
    if (localStorage.getItem("userDetail")) {
      this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
    }
  }
  upload(): void {
    //get image upload file obj;
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      verticalPosition: "top",
    });
  }

  ngOnInit(): void {
    this.targetMonthYearfun();
    this.showNodata = true;
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  downloadfile(val) {
    //debugger;

    if (val.dropdvalue === "") {
      this.enableGuruTargetTemplateBtn = false;
      this.showNodata = true;
    } else {
      this.showNodata = false;
      this.dropdownVal = val;
      this.getTargetList(val);
      this.dropval = val;
      let my = val.dropdvalue.split("-");
      const url =
        this.appService.msrGuruTargetTemplate +
        "?month=" +
        my[0] +
        "&year=" +
        my[1];
      const apiObj = {
        url: url,
        methodType: HttpMethod.POST,
      };
      this.commonService.commonApiCall(apiObj, (res) => {
        this.msrGuruTargetTemplateURL = res.template_url;
        this.enableGuruTargetTemplateBtn = true;
      });
    }
  }
  uploadFile1(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    let my = this.dropdownVal.dropdvalue.split("-");

    if (
      files[0].type ==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      if (files[0].size < 2097152) {
        let selectedfile = files[0];
        let formData: FormData = new FormData();
        formData.append("files", selectedfile);
        formData.append("month", my[0]);
        formData.append("year", my[1]);
        let apiUrl = this.appService.targetUpload;
        let url = apiUrl;
        const apiObj = {
          url: url,
          methodType: HttpMethod.POST,
          requestObj: formData,
        };
        this.commonService.commonApiCall(
          apiObj,
          (res: any, error) => {
            if (error != undefined) {
              this.showToastermessage = true;
              this.showfinalSuccessMsg = "";
              this.showfinalErrorMsg = error.error.msg;
              setTimeout(() => {
                this.showToastermessage = false;
              }, 4000);
            } else {
              this.showfinalErrorMsg = "";
              this.showfinalSuccessMsg = "Uploaded successfully.";
              this.showToastermessage = true;
              this.enableGuruTargetTemplateBtn = false;
              setTimeout(() => {
                this.showToastermessage = false;
              }, 4000);
              setTimeout(() => {
                this.downloadfile(this.dropval);
                //this.enableGuruTargetTemplateBtn = true;
              }, 4000);
            }
            target.value='';
          },
          "multipart/form-data"
        );
      } else {
        this.showfinalSuccessMsg = "";
        this.showToastermessage = true;
        this.showfinalErrorMsg = "File size can’t be over 2MB.";
        setTimeout(() => {
          this.showToastermessage = false;
        }, 3000);
      }
    } else {
      this.showfinalSuccessMsg = "";
      this.showToastermessage = true;
      this.showfinalErrorMsg = "only .xls & .xlsx file formats are allowed.";
      setTimeout(() => {
        this.showToastermessage = false;
      }, 3000);
    }
  }
  uploadFile(evt) {
   
    let my = this.dropdownVal.dropdvalue.split("-");
    if (
      evt[0].type ==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      if (evt[0].size < 2097152) {
        let payload = new FormData();
        payload.append("files", evt[0]);
        payload.append("month", my[0]);
        payload.append("year", my[1]);
        // File can now be uploaded by doing an http post with the payload
        let apiUrl = this.appService.targetUpload;
        let url = apiUrl;
        const apiObj = {
          url: url,
          methodType: HttpMethod.POST,
          requestObj: payload,
        };
        this.commonService.commonApiCall(
          apiObj,
          (res: any, error) => {
            if (error != undefined) {
              this.showToastermessage = true;
              this.showfinalSuccessMsg = "";
              this.showfinalErrorMsg = error.error.msg;
              setTimeout(() => {
                this.showToastermessage = false;
              }, 4000);
            } else {
              this.showfinalErrorMsg = "";
              this.showfinalSuccessMsg = "Uploaded successfully.";
              this.showToastermessage = true;
              this.enableGuruTargetTemplateBtn = false;
              setTimeout(() => {
                this.showToastermessage = false;
              }, 4000);
              setTimeout(() => {
                this.downloadfile(this.dropval);
                //this.enableGuruTargetTemplateBtn = true;
              }, 4000);
            }
          },
          "multipart/form-data"
        );
      } else {
        this.showfinalSuccessMsg = "";
        this.showToastermessage = true;
        this.showfinalErrorMsg = "File size can’t be over 2MB.";
        setTimeout(() => {
          this.showToastermessage = false;
        }, 3000);
      }
    } else {
      this.showfinalSuccessMsg = "";
      this.showToastermessage = true;
      this.showfinalErrorMsg = "only .xls & .xlsx file formats are allowed.";
      setTimeout(() => {
        this.showToastermessage = false;
      }, 3000);
    }
  }

  targetMonthYearfun() {
    //debugger;
    var dt = new Date();
    var dt1 = new Date();
    var mt1 = dt.getMonth() + 1;
    dt1.setMonth(mt1);
    this.currentMonthYear =
      dt.toLocaleDateString("default", { month: "long" }) +
      " " +
      dt.getFullYear();
    this.nextMonthYear =
      dt1.toLocaleDateString("default", { month: "long" }) +
      " " +
      dt1.getFullYear();
    this.currentMonthYearVal = dt.getMonth() + 1 + "-" + dt.getFullYear();
    this.nextMonthYearVal = dt1.getMonth() + 1 + "-" + dt1.getFullYear();
    this.showNodata = false;
  }

  getTargetList(valis) {
    this.dropval = valis;
    let my = valis.dropdvalue.split("-");
    let queryParamList = [];
    let apiUrl = `${this.appService.targetList}`;

    //paging
    let pageParam = "";
    if (this.pgIndex != 0 && this.pgIndex != 1) {
      pageParam = `page=` + this.pgIndex;
      queryParamList.push(pageParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `&` + queryParamList.join("&");
    }

    const url =
      `${apiUrl}` + "?month=" + my[0] + "&year=" + my[1] + apiQueryParam;


    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
    };
    this.commonService.commonApiCall(apiObj, (res, error) => {
      if (
        res &&
        res.hasOwnProperty("results") &&
        res.results &&
        res.results.length
      ) {
        this.showNodata = false;
        this.totalRows = res.count;
        this.dataSource = new MatTableDataSource(res.results);
        this.dataSource.sort = this.sort;
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = res.count;
        this.isLoading = false;
      }
      if (error) {
        if (res.error.msg === "No data found." || error.msg === "No data found." ) {
          this.showNodata = true;
        }
      }
    });
  }

  pageChanged(event: PageEvent) {
    this.pgIndex = event.pageIndex;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex;
    this.isLoading = true;
    this.getTargetList(this.dropdownVal);
  }
}
