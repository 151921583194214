import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';

@Component({
  selector: 'app-gurus-info-list',
  templateUrl: './gurus-info-list.component.html',
  styleUrls: ['./gurus-info-list.component.scss']
})
export class GurusInfoListComponent implements OnInit {
  topgurus = [
    {
      id: 1,
      value: 128,
      case: "Active",
      percentage: "3%",
      text: "(Over last week)",
      class:"increaseValue",
      border:"guru-active-border",
    },
    {
      id: 2,
      value: 80,
      case: "Transacting",
      percentage: "3%",
      text: "(Over last week)",
      class:"decreaseValue",
      border:"guru-transacting-border",
    },
    {
      id: 3,
      value: 48,
      case: "Inactive",
      percentage: "3%",
      text: "(Over last week)",
      class:"increaseValue",
      border:"guru-inactive-border",
    },
  ];
   // Doughnut
   public doughnutChartLabels: Label[] = ['Active', 'Transacting', 'Inactive'];
   public doughnutChartData: SingleDataSet = [[128, 80, 48],];
   public chartColors: Array<any> = [{ backgroundColor: ['#2BB24C', '#FC9D45', '#D83232']}]
   public doughnutChartType: ChartType = 'doughnut';
   public doughnutChartOptions: (ChartOptions) = {
    maintainAspectRatio: false,
      cutoutPercentage:87,
      responsive: true,
      elements: {
        arc: {
          borderWidth: 3,
        },
      },
      legend: {
        display: false,
        position:'right',
        labels: {
          fontColor: '#757575',
          fontSize: 12,
          fontStyle:'normal',
          fontFamily: 'Uni Neue',
          usePointStyle:false,
          padding: 18,
          boxWidth:8
        }
      },
      animation:{
        onComplete: function(){

          // var chartInstance = this.chart,
          // context = chartInstance.ctx;
          // console.log(context);
          // var centerX = 5//canvas.width / 2;
          // var centerY = 5//canvas.height / 2;
          // var radius = 70;

          // context.beginPath();
          // context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
          // context.lineWidth = 8;
          // context.strokeStyle = '#000000';
          // context.stroke();

          // context.fillStyle = 'blue';
          // context.font = '20pt Calibri';
          // context.textAlign = 'center';
          // context.fillText('device', centerX, centerY+context.lineWidth);
          var chartInstance = this.chart,
          ctx = chartInstance.ctx;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          this.data.datasets.forEach(function (dataset, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            var totalguru = 0;
            meta.data.forEach(function (line, index) {
              var data = dataset.data[index]; 
              totalguru = data+totalguru;
              var radius = 40;
              ctx.beginPath();
              //ctx.shadowOffsetX = 0;   
              //ctx.shadowOffsetY = 0;   
              //ctx.shadowBlur = 26;   
              //ctx.shadowColor = "rgba(48, 127, 226, 0.2)";
              ctx.arc(line._model.x, line._model.y, radius, 0, 2 * Math.PI, false);
              ctx.fillStyle = "#fff";
              ctx.fill();
              // ctx.lineWidth = 8;
              // ctx.strokeStyle = '#000000';
              // ctx.stroke();
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';
              ctx.fillStyle = '#282828';
              ctx.font = '25px Uni Neue';
              ctx.fillText(totalguru, line._model.x, line._model.y + 5);  
              ctx.fillStyle = '#757575';
              ctx.font = '12px Uni Neue';
              ctx.fillText('Total Gurus', line._model.x, line._model.y + 25);  

              //box-shadow: 0px 0px 26.9582px rgba(48, 127, 226, 0.2);
              //ctx.font = 16;
              //ctx.fillStyle = '#282828';
              //ctx.fillText("Hello Hi How are you", line._model.x, line._model.y - 12);

            });
          });
        }

        //     var canvasWidthvar = $('#chart-area').width();
        //     var canvasHeight = $('#chart-area').height();
        //     //this constant base on canvasHeight / 2.8em
        //     var constant = 114;
        //     var fontsize = (canvasHeight/constant).toFixed(2);
        //     ctx.font=fontsize +"em Verdana";
        //     ctx.textBaseline="middle"; 
        //     var total = 0;
        //     $.each(doughnutData,function() {
        //       total += parseInt(this.value,10);
        //   });
        //  var tpercentage = ((doughnutData[0].value/total)*100).toFixed(2)+"%";
        //  var textWidth = ctx.measureText(tpercentage).width;
       
        //   var txtPosx = Math.round((canvasWidthvar - textWidth)/2);
        //    ctx.fillText(tpercentage, txtPosx, canvasHeight/2);
        //}
      },
      tooltips: {enabled: false},
      hover: {mode: null},
      events: ["click", ],
   }
   constructor() { }
 
   ngOnInit(): void {
   }
}