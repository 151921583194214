<!-- <div class="login-wrapper">
  <div class="login-header">
    <div class="logo">
      <img src="./assets/images/tata-motors-logo.svg" alt="" />
    </div>
    <div class="partner-logos">
      <ul>
        <li><img src="./assets/images/login/champion.svg" alt="" /></li>
        <li><img src="./assets/images/login/engine.svg" alt="" /></li>
        <li><img src="./assets/images/login/tata-guru.svg" alt="" /></li>
        <li>
          <img src="./assets/images/login/tata-genuine-logo.svg" alt="" />
        </li>
      </ul>
    </div>
  </div>
  <div class="loginPanel">
    <div class="loginPanelLeft">
      <h1>
        TURNING <b>DREAMS</b> <span>INTO <b>REALITY.</b></span>
      </h1>
      <div class="gift-img">
        <img src="./assets/images/gifts.svg" alt="" />
      </div>
    </div>
    <div class="loginPanelFormSec">
      <div class="loginPanelForm" *ngIf="showloginForm">
        <form [formGroup]="loginForm" (ngSubmit)="loginFormData()">
          <h2>Login</h2>
          <p>
            Please enter your mobile number and we will <br />
            send you an OTP on your number
          </p>
          <div class="form-row">
            <div class="form-col">
              <label for="mobileNum" class="form-label"> Mobile Number</label>
              <input type="text" autocomplete="off" class="form-control" id="mobileNum" placeholder="Type here" formControlName="mobileNum"
                appOnlyNumeric maxlength="10" [ngClass]="{
                  'invalid-input':
                    isSubmitted && loginForm.controls['mobileNum'].errors
                }" />
              <div *ngIf="isSubmitted && loginForm.controls['mobileNum'].invalid" class="error-message">
                <ng-container *ngIf="loginForm.controls['mobileNum'].errors.required">
                  Mobile Number is required.
                </ng-container>
                <ng-container *ngIf="
                    loginForm.controls['mobileNum'].errors.pattern ||
                    loginForm.controls['mobileNum'].errors.min
                  ">
                  Please enter a valid Mobile Number.
                </ng-container>
              </div>
            </div>

            <div class="form-btn">
              <button mat-flat-button color="primary" class="formSubmitbtn">
                Request OTP
              </button>
            </div>
            <div [ngClass]="
                showClasses ? 'custom-login-error' : 'custom-success-message'
              " *ngIf="showLockedMessage">
              {{ showLockedMessage }}
            </div>
          </div>
        </form>
      </div>
      <div class="loginPanelForm forgotPassPanel" *ngIf="!showloginForm">
        <form [formGroup]="enterOTPForm" (ngSubmit)="enterOTP()">
          <h2>Enter OTP</h2>
          <p>
            Please enter the OTP that has been sent to your<br />
            mobile number, which is valid for 5 minutes
          </p>
          <div class="form-row">
            <div class="form-col">
              <label for="otp" class="form-label">OTP</label>
              <input type="text" class="form-control" id="otp" placeholder="Enter 6 Digit OTP" maxlength="6"
                formControlName="otp" appOnlyNumeric [ngClass]="{
                  'invalid-input':
                    isOTPSubmitted && enterOTPForm.controls['otp'].errors
                }" />
              <div *ngIf="isOTPSubmitted && enterOTPForm.controls['otp'].invalid" class="error-message">
                <ng-container *ngIf="enterOTPForm.controls['otp'].errors.required">
                  OTP is required.
                </ng-container>
                <ng-container *ngIf="enterOTPForm.controls['otp'].errors.pattern">
                  Please enter valid OTP.
                </ng-container>
              </div>
            </div>

            <div class="form-forgot-link">
              <a href="javascript:void(0)" (click)="activeLoginForm()">Login with different mobile number</a>
              <a href="javascript:void(0)" (click)="resendOtp()">Resend OTP</a>
            </div>

            <div class="form-btn">
              <button mat-flat-button color="primary" type="submit" [disabled]="showmultipleErrorMessage"
                class="formSubmitbtn">
                Verify OTP
              </button>
            </div>
            <div class="custom-success-message custom-warning-msg" *ngIf="showmultipleErrorMessage">
              You already have an active session or previous session was closed
              abruptly! <b>Are you sure want to logout Previous Session?</b>
              <br />
              <span class="btn-item">
                <a (click)="clearPrevSession()" class="primary-button success">Ok</a>
                <a (click)="pagerefresh()" class="primary-button cancel">Cancel</a>
              </span>
            </div>
            <div class="custom-login-error" *ngIf="showOTPErrorMessage">
              {{ showOTPErrorMessage }}
            </div>
            <div class="custom-success-message" *ngIf="resendOtpmsg">
              {{ resendOtpmsg }}
            </div>
          </div>
        </form>
      </div>

      <h2 class="tollfreeNum">The New Toll Free Number is 1800 123 1332</h2>
    </div>
  </div>
</div> -->




<div class="login-wrapper">
  <div class="login-header">
    <div class="logo">
      <img src="./assets/images/tata-motors-logo.svg" alt="" />
    </div>
    <div class="partner-logos">
      <ul>
        <li><img src="./assets/images/login/champion.svg" alt="" /></li>
        <li><img src="./assets/images/login/engine.svg" alt="" /></li>
        <li><img src="./assets/images/login/tata-guru.svg" alt="" /></li>
        <li>
          <img src="./assets/images/login/tata-genuine-logo.svg" alt="" />
        </li>
      </ul>
    </div>
  </div>
  <div class="loginPanel">
    <div class="loginPanelLeft">
      <h1>
        TURNING <b>DREAMS</b> <span>INTO <b>REALITY.</b></span>
      </h1>
      <div class="gift-img">
        <img src="./assets/images/gifts.svg" alt="" />
      </div>
    </div>
    <div class="loginPanelFormSec">
      <!-- <ng-container class="d-none" style="display: none;">
        <div class="loginPanelForm" *ngIf="showloginForm">
          <form [formGroup]="loginForm" (ngSubmit)="loginFormData()">
            <h2>Login</h2>
            <p>
              Please enter your mobile number and we will <br />
              send you an OTP on your number
            </p>
            <div class="form-row">
              <div class="form-col">
                <label for="mobileNum" class="form-label"> Mobile Number</label>
                <input type="text" class="form-control" id="mobileNum" placeholder="Type here" formControlName="mobileNum"
                  appOnlyNumeric maxlength="10" [ngClass]="{
                    'invalid-input':
                      isSubmitted && loginForm.controls['mobileNum'].errors
                  }" />
                <div *ngIf="isSubmitted && loginForm.controls['mobileNum'].invalid" class="error-message">
                  <ng-container *ngIf="loginForm.controls['mobileNum'].errors.required">
                    Mobile Number is required.
                  </ng-container>
                  <ng-container *ngIf="
                      loginForm.controls['mobileNum'].errors.pattern ||
                      loginForm.controls['mobileNum'].errors.min
                    ">
                    Please enter a valid Mobile Number.
                  </ng-container>
                </div>
              </div>
  
              <div class="form-btn">
                <button mat-flat-button color="primary" class="formSubmitbtn">
                  Request OTP
                </button>
              </div>
              <div [ngClass]="
                  showClasses ? 'custom-login-error' : 'custom-success-message'
                " *ngIf="showLockedMessage">
                {{ showLockedMessage }}
              </div>
            </div>
          </form>
        </div>
        <div class="loginPanelForm forgotPassPanel" *ngIf="!showloginForm">
          <form [formGroup]="enterOTPForm" (ngSubmit)="enterOTP()">
            <h2>Enter OTP</h2>
            <p>
              Please enter the OTP that has been sent to your<br />
              mobile number, which is valid for 5 minutes
            </p>
            <div class="form-row">
              <div class="form-col">
                <label for="otp" class="form-label">OTP</label>
                <input type="text" class="form-control" id="otp" placeholder="Enter 6 Digit OTP" maxlength="6"
                  formControlName="otp" appOnlyNumeric [ngClass]="{
                    'invalid-input':
                      isOTPSubmitted && enterOTPForm.controls['otp'].errors
                  }" />
                <div *ngIf="isOTPSubmitted && enterOTPForm.controls['otp'].invalid" class="error-message">
                  <ng-container *ngIf="enterOTPForm.controls['otp'].errors.required">
                    OTP is required.
                  </ng-container>
                  <ng-container *ngIf="enterOTPForm.controls['otp'].errors.pattern">
                    Please enter valid OTP.
                  </ng-container>
                </div>
              </div>
  
              <div class="form-forgot-link">
                <a href="javascript:void(0)" (click)="activeLoginForm()">Login with different mobile number</a>
                <a href="javascript:void(0)" (click)="resendOtp()">Resend OTP</a>
              </div>
  
              <div class="form-btn">
                <button mat-flat-button color="primary" type="submit" [disabled]="showmultipleErrorMessage"
                  class="formSubmitbtn">
                  Verify OTP
                </button>
              </div>
              <div class="custom-success-message custom-warning-msg" *ngIf="showmultipleErrorMessage">
                You already have an active session or previous session was closed
                abruptly! <b>Are you sure want to logout Previous Session?</b>
                <br />
                <span class="btn-item">
                  <a (click)="clearPrevSession()" class="primary-button success">Ok</a>
                  <a (click)="pagerefresh()" class="primary-button cancel">Cancel</a>
                </span>
              </div>
              <div class="custom-login-error" *ngIf="showOTPErrorMessage">
                {{ showOTPErrorMessage }}
              </div>
              <div class="custom-success-message" *ngIf="resendOtpmsg">
                {{ resendOtpmsg }}
              </div>
            </div>
          </form>
        </div>
      </ng-container> -->
      <ng-container>
        <div class="loginPanelForm">
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <h2>Login</h2>
            <!-- <p>
              Please enter your username & password and we will <br />
              send you an OTP on your number
            </p> -->
            <div class="row">
              <!-- <div class="form-col"> -->
                <label for="username" class="form-label"> User Id</label>
                <input type="text" class="form-control" id="username" placeholder="Enter User Id" formControlName="username"
                   maxlength="50" [ngClass]="{
                    'invalid-input':
                      isSubmitted && loginForm.controls['username'].errors
                  }" />
                <div *ngIf="isSubmitted && loginForm.controls['username'].invalid" class="error-message">
                  <ng-container *ngIf="loginForm.controls['username'].errors.required">
                    User Id is required.
                  </ng-container>
                  <ng-container *ngIf="
                      loginForm.controls['username'].errors.pattern ||
                      loginForm.controls['username'].errors.min
                    ">
                    Please enter a valid User Id.
                  </ng-container>
                </div>
              <!-- </div> -->
              
            </div>`
            <div class="row">
              <!-- <div class="form-col"> -->
                <label for="password" class="form-label"> Password</label>
                <input type="password" class="form-control" id="password" placeholder="Enter Password" formControlName="password"
                   maxlength="50" [ngClass]="{
                    'invalid-input':
                      isSubmitted && loginForm.controls['password'].errors
                  }" />
                <div *ngIf="isSubmitted && loginForm.controls['password'].invalid" class="error-message">
                  <ng-container *ngIf="loginForm.controls['password'].errors.required">
                    Password is required.
                  </ng-container>
                  <ng-container *ngIf="
                      loginForm.controls['password'].errors.pattern ||
                      loginForm.controls['password'].errors.min
                    ">
                    Please enter a valid Password.
                  </ng-container>
                </div>
              <!-- </div> -->
            </div>
            <div class="row">
              <div class="form-btn">
                <button mat-flat-button color="primary" class="formSubmitbtn">
                  Login
                </button>
              </div>
              <div class="custom-success-message custom-warning-msg" *ngIf="showmultipleErrorMessage">
                You already have an active session or previous session was closed
                abruptly! <b>Are you sure want to logout Previous Session?</b>
                <br />
                <span class="btn-item">
                  <a (click)="clearPrevSession()" class="primary-button success">Ok</a>
                  <a (click)="pagerefresh()" class="primary-button cancel">Cancel</a>
                </span>
              </div>
            </div>
            <div class="custom-login-error" *ngIf="showOTPErrorMessage">
              {{ showOTPErrorMessage }}
            </div>
            <div class="custom-success-message" *ngIf="resendOtpmsg">
              {{ resendOtpmsg }}
            </div>
            <div class="custom-login-error" *ngIf="apiErrorMessage">
              {{ apiErrorMessage }}
            </div>
          </form>
        </div>
      </ng-container>

      <h2 class="tollfreeNum">The New Toll Free Number is 1800 123 1332</h2>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>