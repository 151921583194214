<div class="profile-section">
  <h2>my profile</h2>
  <div class="comn-box personal-detail-box">
    <div class="profile-left-col">
      <div class="profile-container">
        <div class="profile-display-container">
          <div class="guru-profile-image">
            <img [src]="
                profileDetail.profile_image
                  ? profileDetail.profile_image
                  : './assets/images/demo-img.png'
              " />
          </div>
          <div class="profile-text-wrap">
            <h3>
              {{ (profileDetail.first_name + " " + profileDetail.last_name) || "NA" }}

              <span>{{ profileDetail.role || "NA" }}</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="profile-right-col">
      <div class="detail-sec" *ngIf="profileDetail.role == 'MSR'">
        <h3>Personal Details</h3>
        <div class="detail-items">
          <div class="detail-col">
            <div class="detail-label">Joining Date:</div>
            <div class="detail-val">
              {{ profileDetail.joining_date | date: "dd/MM/yyyy" || "NA" }}
            </div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Area:</div>
            <div class="detail-val">{{ profileDetail.area || "NA" }}</div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Email:</div>
            <div class="detail-val">{{ profileDetail.email_id || "NA" }}</div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Contact:</div>
            <div class="detail-val">
              <div class="view-contact">
                +91 {{ profileDetail.phone_number || "NA" }}
              </div>
            </div>
          </div>

          <ng-container *ngFor="let item of profileDetail.distributor">
            <div class="detail-col">
              <div class="detail-label">Distributor Name:</div>
              <div class="detail-val">
                {{ (item.user__first_name + " " + item.user__last_name) || "NA" }}
              </div>
            </div>
            <div class="detail-col">
              <div class="detail-label">Distributor Area:</div>
              <div class="detail-val">{{ item.area  || "NA" }}</div>
            </div>
            <div class="detail-col">
              <div class="detail-label">Distributor Email:</div>
              <div class="detail-val">{{ item.user__email || "NA" }}</div>
            </div>
            <div class="detail-col">
              <div class="detail-label">Distributor Phone Number:</div>
              <div class="detail-val">+91 {{ item.phone_number || "NA" }}</div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="detail-sec" *ngIf="profileDetail.role == 'Distributor'">
        <h3>Personal Details</h3>
        <div class="detail-items">
          <div class="detail-col">
            <div class="detail-label">Joining Date:</div>
            <div class="detail-val">
              {{ profileDetail.joining_date | date: "dd/MM/yyyy" || "NA" }}
            </div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Area:</div>
            <div class="detail-val">{{ profileDetail.area || "NA"}}</div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Email:</div>
            <div class="detail-val">{{ profileDetail.email_id || "NA"}}</div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Contact:</div>
            <div class="detail-val">
              <div class="view-contact">
                +91 {{ profileDetail.phone_number || "NA"}}
              </div>
            </div>
          </div>

          <ng-container *ngFor="let item of profileDetail.apm">
            <div class="detail-col">
              <div class="detail-label">APM Name:</div>
              <div class="detail-val">
                {{ (item.user__first_name + " " + item.user__last_name) || "NA" }}
              </div>
            </div>
            <div class="detail-col">
              <div class="detail-label">APM Area:</div>
              <div class="detail-val">{{ item.area || "NA" }}</div>
            </div>
            <div class="detail-col">
              <div class="detail-label">APM Email:</div>
              <div class="detail-val">{{ item.user__email || "NA"}}</div>
            </div>
            <div class="detail-col">
              <div class="detail-label">APM Phone Number:</div>
              <div class="detail-val">+91 {{ item.phone_number || "NA"}}</div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="detail-sec" *ngIf="profileDetail.role == 'APM'">
        <h3>Personal Details</h3>
        <div class="detail-items">
          <div class="detail-col">
            <div class="detail-label">Joining Date:</div>
            <div class="detail-val">
              {{ profileDetail.joining_date | date: "dd/MM/yyyy" || "NA"}}
            </div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Email:</div>
            <div class="detail-val">{{ profileDetail.email_id || "NA"}}</div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Contact:</div>
            <div class="detail-val">
              <div class="view-contact">
                +91 {{ profileDetail.phone_number || "NA"}}
              </div>
            </div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Area:</div>
            <div class="detail-val">{{ profileDetail.area || "NA"}}</div>
          </div>
          <ng-container *ngFor="let item of profileDetail.rpm">
            <div class="detail-col">
              <div class="detail-label">RPM Name:</div>
              <div class="detail-val">
                {{ (item.user__first_name + " " + item.user__last_name) || "NA" }}
              </div>
            </div>
            <div class="detail-col">
              <div class="detail-label">RPM Region:</div>
              <div class="detail-val">{{ item.region || "NA"}}</div>
            </div>
            <div class="detail-col">
              <div class="detail-label">RPM Email:</div>
              <div class="detail-val">{{ item.user__email || "NA"}}</div>
            </div>
            <div class="detail-col">
              <div class="detail-label">RPM Phone Number:</div>
              <div class="detail-val">+91 {{ item.phone_number || "NA"}}</div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="detail-sec" *ngIf="profileDetail.role == 'RPM'">
        <h3>RPM Personal Details</h3>
        <div class="detail-items">
          <div class="detail-col">
            <div class="detail-label">Joining Date:</div>
            <div class="detail-val">
              {{ profileDetail.joining_date | date: "dd/MM/yyyy" || "NA"}}
            </div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Email:</div>
            <div class="detail-val">{{ profileDetail.email_id || "NA"}}</div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Contact:</div>
            <div class="detail-val">
              <div class="view-contact">
                +91 {{ profileDetail.phone_number || "NA"}}
              </div>
            </div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Region:</div>
            <div class="detail-val">{{ profileDetail.area || "NA"}}</div>
          </div>

          <!-- <div class="detail-col">
            <div class="detail-label">Alternate Phone Number:</div>
            <div class="detail-val">
              +91 {{ profileDetail.alternate_phone_number }}
            </div>
          </div> -->
        </div>
      </div>
      <div class="detail-sec" *ngIf="profileDetail.role == 'SuperAdmin'">
        <h3>Super Admin Personal Details</h3>
        <div class="detail-items">
          <div class="detail-col">
            <div class="detail-label">Joining Date:</div>
            <div class="detail-val">
              {{ profileDetail.joining_date | date: "dd/MM/yyyy" || "NA"}}
            </div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Email:</div>
            <div class="detail-val">{{ profileDetail.email_id || "NA"}}</div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Contact:</div>
            <div class="detail-val">
              <div class="view-contact">
                +91 {{ profileDetail.phone_number || "NA"}}
              </div>
            </div>
          </div>
          <!-- <div class="detail-col">
            <div class="detail-label">Region:</div>
            <div class="detail-val">{{ profileDetail.area }}</div>
          </div> -->

          <div class="detail-col">
            <div class="detail-label">Alternate Phone Number:</div>
            <div class="detail-val">
              +91 {{ profileDetail.alternate_phone_number || "NA"}}
            </div>
          </div>
        </div>
      </div>
      <div class="detail-sec" *ngIf="profileDetail.role == 'DEALER'">
        <h3>Dealer Personal Details</h3>
        <div class="detail-items">
          <div class="detail-col">
            <div class="detail-label">Joining Date:</div>
            <div class="detail-val">
              {{ profileDetail.joining_date | date: "dd/MM/yyyy" || "NA" }}
            </div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Email:</div>
            <div class="detail-val">{{ profileDetail.email_id || "NA" }}</div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Contact:</div>
            <div class="detail-val">
              <div class="view-contact">
              +91 {{ profileDetail.phone_number || "NA"  }}
              </div>
            </div>
          </div>
          <div class="detail-col">
            <div class="detail-label">Region:</div>
            <div class="detail-val">{{ profileDetail.area || "NA" }}</div>
          </div>

          <div class="detail-col">
            <div class="detail-label">Alternate Phone Number:</div>
            <div class="detail-val">
            +91 {{ profileDetail.alternate_phone_number  || "NA" }}
            </div>
          </div>
          <!-- <div class="detail-col">
            <div class="detail-label">Area:</div>
            <div class="detail-val">{{ profileDetail.area || "NA"}}</div>
          </div> -->
          <div class="detail-col">
            <div class="detail-label">Gender:</div>
            <div class="detail-val">{{ profileDetail.gender || "NA" }}</div>
          </div>
        </div>
    </div>
  </div>