import {
  Component,
  Input,
  OnInit,
  OnChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { JobCardTGPFitments } from "./job-card-tgp-fitment";
import { TableColumn } from "../../../shared/guru-table/TableColumn";
import { Sort } from "@angular/material/sort";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { HttpMethod } from "src/app/constants/http-handlers";
import { Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from "src/app/services/excel.service";


@Component({
  selector: "app-job-card-tgp-fitment",
  templateUrl: "./job-card-tgp-fitment.component.html",
  styleUrls: ["./job-card-tgp-fitment.component.scss"],
  providers: [DatePipe],
})
export class JobCardTGPFitmentComponent implements OnInit, OnChanges {
  @Input() selectedView: any;
  @Input() filterObject: any = {};

  jobSummary: any;
  orders: JobCardTGPFitments[];
  ordersTableColumns: TableColumn[];
  isDataReady: boolean = false;
  currentYear = new Date().getFullYear();
  prevYear = this.currentYear - 1;
  searchText: string = "";
  recordCount: number = 0;
  pageIndex = 0;
  role: string;
  zone: any;
  constructor(
    private appService: AppService,
    private commonService: CommonService,
    private excelService:ExcelService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
if (this.role === 'RPM'||this.role === 'DEALER') {
  this.zone = JSON.parse(localStorage.getItem('userDetail')).userdetails.region;
}
    this.initializeColumns();
    this.isDataReady = false;
    this.getJobSummaryTableData();
  }
  ngOnChanges(): void {
    this.getJobSummary();
  }
  receivedPageHandler(valueEmitted: any) {
    this.pageIndex = valueEmitted;
    this.isDataReady = false;
    this.getJobSummaryTableData();
  }

  searchRecords() {
    this.pageIndex = 0;
    this.isDataReady = false;
    this.getJobSummaryTableData();
  }

  checkText(event: KeyboardEvent) {
    if (event.code == "Enter" || this.searchText.trim() == "") {
      this.searchRecords();
    }
  }

  sortData(sortParameters: Sort) {
    //debugger;
    const keyName = sortParameters.active;
    if (sortParameters.direction === "asc") {
      this.orders = this.orders.sort(
        (a: JobCardTGPFitments, b: JobCardTGPFitments) =>
          a[keyName].localeCompare(b[keyName])
      );
      return "";
    } else if (sortParameters.direction === "desc") {
      this.orders = this.orders.sort(
        (a: JobCardTGPFitments, b: JobCardTGPFitments) =>
          b[keyName].localeCompare(a[keyName])
      );
      return "";
    } else {
      return "";
      //return this.getJobSummaryTableData();
    }
  }

  initializeColumns(): void {
    this.ordersTableColumns = [
      {
        name: "Job card Id",
        dataKey: "jobID",
        position: "center",
        isSortable: false,
      },
      {
        name: "Job Card Opening Date",
        dataKey: "jobOpeningDt",
        position: "center",
        isSortable: true,
      },
      {
        name: "Job Card Opening Time",
        dataKey: "jobOpeningTime",
        position: "center",
        isSortable: true,
      },
      {
        name: "Job card Category",
        dataKey: "jobCategory",
        position: "center",
        isSortable: true,
      },
      {
        name: "Part Revenue",
        dataKey: "partRevenue",
        position: "center",
        isSortable: true,
      },
      {
        name: "Total Part Price",
        dataKey: "totaPartPrice",
        position: "center",
        isSortable: true,
      },
      {
        name: "Lass Name",
        dataKey: "lassName",
        position: "center",
        isSortable: true,
      },
      {
        name: "Lass Number",
        dataKey: "lassNumber",
        position: "center",
        isSortable: true,
      },
      {
        name: "Garage Area",
        dataKey: "garage",
        position: "center",
        isSortable: true,
      },
      {
        name: "Guru Name",
        dataKey: "guru",
        position: "center",
        isSortable: true,
      },
      {
        name: "Guru Number",
        dataKey: "guruNumber",
        position: "center",
        isSortable: true,
      },
      {
        name: "Dealer Name",
        dataKey: "dealer_name",
        position: "center",
        isSortable: true,
      },
      {
        name: "SSO",
        dataKey: "sso",
        position: "center",
        isSortable: true,
      },
      {
        name: "State",
        dataKey: "state",
        position: "center",
        isSortable: true,
      },
      {
        name: "Region",
        dataKey: "region",
        position: "center",
        isSortable: true,
      },
      {
        name: "Pincode",
        dataKey: "pincode",
        position: "center",
        isSortable: true,
      },
      {
        name:"Emission Norms",
        dataKey:"emissionNorms",
        position:"center",
        isSortable:false
      },
      {
        name: "Vehicle Registration Number",
        dataKey: "vehicleRegNum",
        position: "center",
        isSortable: true,
      },
      {
        name: "Vehicle Chassis Number",
        dataKey: "vehicleChsisNum",
        position: "center",
        isSortable: true,
      },
      {
        name: "LOB",
        dataKey: "lob",
        position: "center",
        isSortable: true,
      },
      {
        name: "PL",
        dataKey: "pl",
        position: "center",
        isSortable: false,
      },
      {
        name: "PPL",
        dataKey: "ppl",
        position: "center",
        isSortable: true,
      },
      {
        name: "Labour Charges",
        dataKey: "labourCharge",
        position: "center",
        isSortable: true,
      },
      {
        name: "Sr Type",
        dataKey: "srType",
        position: "center",
        isSortable: true,
      },
      {
        name: "Odometer",
        dataKey: "odometer",
        position: "center",
        isSortable: true,
      },
      // {
      //   name: "Job Status",
      //   dataKey: "jobstatus",
      //   position: "center",
      //   isSortable: true,
      // },
      {
        name: "JC Number",
        dataKey: "jcnumber",
        position: "center",
        isSortable: true,
      },
      {
        name: "JC Status",
        dataKey: "jcstatus",
        position: "center",
        isSortable: true,
      },
      // {
      //   name: "SR Status",
      //   dataKey: "srstatus",
      //   position: "center",
      //   isSortable: true,
      // },
      {
        name: "Claim Status",
        dataKey: "claimstatus",
        position: "center",
        isSortable: true,
      },
      {
        name: "Action",
        dataKey: "action",
        position: "center",
        isSortable: true,
      },
      {
        name: "Feedback",
        dataKey: "feedback",
        position: "center",
        isSortable: true,
      },
      {
        name: "Rating",
        dataKey: "rating",
        position: "center",
        isSortable: true,
      },
      {
        name: "Added By",
        dataKey: "addedby",
        position: "center",
        isSortable: true,
      }
    ];
  }
  showToastermessage:boolean=false;
  showfinalErrorMsg:string="";

  async downloadReport(){
    try{
      this.spinner.show();
      let today = new Date();
      let apiUrl =  this.appService.jobSummaryMisReport;
      let to_date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
      let from_date = this.datePipe.transform(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()), "yyyy-MM-dd");
      let reqObj = {
          "from_date": this.formcontrolValues && this.formcontrolValues.fromDate?this.datePipe.transform(this.formcontrolValues.fromDate,"yyy-MM-dd"):from_date,
          "to_date": this.formcontrolValues && this.formcontrolValues.toDate?this.datePipe.transform(this.formcontrolValues.toDate,"yyy-MM-dd"):to_date,
          "state": this.formcontrolValues && this.formcontrolValues.state?this.formcontrolValues.state: this.formcontrolValues && "",
          "zone":  this.role === 'RPM'||this.role === 'DEALER' ? this.zone :(this.formcontrolValues && this.formcontrolValues.zone?this.formcontrolValues.zone:""),          "guru_name": this.searchText?this.searchText:"",
          "guru_contact": this.formcontrolValues && this.formcontrolValues.guru_number?this.formcontrolValues.guru_number:"",
          "job_id": this.formcontrolValues && this.formcontrolValues.jobcardid?this.formcontrolValues.jobcardid:"",
          "jobcard_opening_date": this.formcontrolValues && this.formcontrolValues.jobCardOpeningDate?this.datePipe.transform(this.formcontrolValues.jobCardOpeningDate, "yyy-MM-dd"):"",
          "case_type": this.formcontrolValues && this.formcontrolValues.case_type?this.formcontrolValues.case_type:"",
          "chassis_number": this.formcontrolValues && this.formcontrolValues.chasis_no?this.formcontrolValues.chasis_no:"",
          "lass_name": this.formcontrolValues && this.formcontrolValues.lassnme?this.formcontrolValues.lassnme:"",
          "lass_number": this.formcontrolValues && this.formcontrolValues.lassContactNo?this.formcontrolValues.lassContactNo:"",
          "emission_norm":this.formcontrolValues && this.formcontrolValues.emissionNorms?this.formcontrolValues.emissionNorms:"",
          "lob": this.formcontrolValues && this.formcontrolValues.lob?this.formcontrolValues.lob:"",
          "ppl": this.formcontrolValues && this.formcontrolValues.ppl?this.formcontrolValues.ppl:"",
          "pl": this.formcontrolValues && this.formcontrolValues.pl?this.formcontrolValues.pl:"",
          "offset":0,
          "limit":250
      };
      let totalResult=0;
      let data:any[] = [];
      let res = await this.commonService.postAsyncRequest(apiUrl,reqObj);
      if(res){
        totalResult = res.count;
        data = res.data;
      }
      let rou = Math.floor(totalResult/250);
      rou = (totalResult%250>0) && (rou > 0)?rou+1:rou; 
      if(rou > 1 ){
        for(var i=1;i<rou;i++){
          reqObj.offset = (i * 250);
          res = await this.commonService.postAsyncRequest(apiUrl,reqObj);
          if(res && res.success && res.data.length){
            data = [...data, ...res.data];
          }
        }
      }
      let finalData:Job_Card_Fitement[] = [];
      for (let row of data) {
        const ListInput: Job_Card_Fitement = {} as Job_Card_Fitement;
        ListInput.Job_ID = `${row.id?row.id:"NA"}`
        ListInput.Job_Opening_Dt = this.datePipe.transform(`${row.created_date }`,"dd/MM/YYYY")
        ListInput.Job_Opening_Time = this.datePipe.transform(`${row.created_date }`,"h:mm a")
        ListInput.Job_Category = `${row.case_type?row.case_type:"NA" }`
        ListInput.Part_Revenue = `${row.jobcard_part_revenue.part_revenue}`
        ListInput.Total_Part_Price = `${row.jobcard_part_revenue.total_price}`,
        ListInput.Lass_Name = `${row["guru_details"].lass_name}`
        ListInput.Lass_Number = `${row["guru_details"].lass_number}`
        ListInput.Garage = `${row["guru_details"].garage_name}`
        ListInput.Guru = `${row["guru_details"].mechanic_name}`
        ListInput.Guru_Number = `${row["guru_details"].phone_number}`
        ListInput.Dealer_Name = row["guru_details"].dealer
          ListInput.SSO = row["guru_details"].sso
        ListInput.State = `${row['guru_details'].state_name }`
        // ListInput.City = `${row['guru_details'].city }`
        ListInput.Region = `${row['guru_details'].zone }`
        ListInput.Pincode = `${row['guru_details'].pincode }`
        ListInput.Emission_Norms = `${row.emission_norm?row.emission_norm:"NA"}`
        ListInput.Vehicle_Registration_Number = `${row.reg_num?row.reg_num:"NA" }`
        ListInput.Vehicle_Chassis_Number = `${row.chassis_number?row.chassis_number:"NA" }`
        ListInput.Lob = `${row.lob?row.lob:"NA" }`
        ListInput.Pl = `${row.pl?row.pl:"NA" }`
        ListInput.Ppl = `${row.ppl?row.ppl:"NA" }`
        ListInput.Labour_Charge = `${row.labour_charges?row.labour_charges:"NA"}`
        ListInput.Feedback = `${row.feedback?row.feedback:"NA" }`
        ListInput.Rating = `${row.rating?row.rating:"NA" }`
        ListInput.AddedBy = `${row.added_by?row.added_by:"NA" }`
        finalData.push(ListInput);
      }
      if(finalData.length > 0 ){
        this.excelService.exportCancellationAsExcelFile(finalData, 'job_summary_report');
      }else{
        this.showToastermessage = true;
        this.showfinalErrorMsg =
          "No Data For Download";
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
     
    }catch(error){
      this.showToastermessage = true;
      this.showfinalErrorMsg =
        "No Data For Download";
      setTimeout(() => {
        this.showfinalErrorMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
    this.spinner.hide();
  
}
  getJobSummary() {
    let viewType = this.commonService.getViewType(this.selectedView);

    let apiUrl = this.appService.jobSummary;
    let queryParamList = [];
    this.jobSummary = {};

    //view type param
    let viewTypeParam = "";
    if (viewType != "") {
      viewTypeParam = "view_by=" + viewType;
      queryParamList.push(viewTypeParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `?` + queryParamList.join("&");
    }

    const url = `${apiUrl}` + apiQueryParam;

    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res && res.hasOwnProperty("jobcard_data") && res.jobcard_data) {
       this.jobSummary = res.jobcard_data;
      }
    });
  }
  formcontrolValues:any;
  formControlValue(event){
    this.pageIndex = 0;
    this.formcontrolValues = event;
    this.getJobSummaryTableData();
  }
  refreshList(){
    this.pageIndex = 0;
    this.formControlValue(undefined)
  }
  getJobSummaryTableData() {
    try{
      this.spinner.show();
      this.isDataReady = false;
      this.orders = [];
      this.recordCount = 0;

      let apiUrl = this.appService.jobSummaryMisReport;
      let queryParamList = [];
      let viewType = "quarterly"; //this.commonService.getViewType(this.selectedView);

      //paging
      let pageParam = "";
      if (this.pageIndex > 0) {
        pageParam = `page=` + (this.pageIndex + 1);
        queryParamList.push(pageParam);
      }
      let viewTypeParam = "";
      if (viewType != "") {
        viewTypeParam = "view_by=" + viewType;
        queryParamList.push(viewTypeParam);
      }

      let dtObj = this.commonService.getDateRangObject(
        viewType,
        "",
        "YYYY-MM-dd"
      );
      let filtersApplied = false;
      for (let key in this.formcontrolValues) {
        if (this.formcontrolValues[key] && key !== 'fromDate' && key !== 'toDate') {
          filtersApplied = true;
          break;
        }
      }
  
      let today = new Date();
      let to_date = this.datePipe.transform(new Date(),"yyy-MM-dd");
      let from_date = this.datePipe.transform(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()), "yyy-MM-dd");
  
      let requestObj = {
          "from_date": filtersApplied ? null : (this.formcontrolValues && this.formcontrolValues.fromDate ? this.datePipe.transform(this.formcontrolValues.fromDate,"yyy-MM-dd") : from_date),
          "to_date": filtersApplied ? null : (this.formcontrolValues && this.formcontrolValues.toDate ? this.datePipe.transform(this.formcontrolValues.toDate,"yyy-MM-dd") : to_date),
          "state": this.formcontrolValues && this.formcontrolValues.state?this.formcontrolValues.state: this.formcontrolValues && "",
          // "city": this.formcontrolValues && this.formcontrolValues.city?this.formcontrolValues.city:"",
          // "name": this.formcontrolValues ,
          "zone":  this.role === 'RPM'||this.role === 'DEALER' ? this.zone :(this.formcontrolValues && this.formcontrolValues.zone?this.formcontrolValues.zone:""),
          "guru_name": this.formcontrolValues && this.formcontrolValues.guru_name?this.formcontrolValues.guru_name:this.searchText,
          "guru_contact": this.formcontrolValues && this.formcontrolValues.guru_number?this.formcontrolValues.guru_number:"",
          "job_id": this.formcontrolValues && this.formcontrolValues.jobcardid?this.formcontrolValues.jobcardid:"",
          "jobcard_opening_date": this.formcontrolValues && this.formcontrolValues.jobCardOpeningDate?this.datePipe.transform(this.formcontrolValues.jobCardOpeningDate, "yyy-MM-dd"):"",
          "case_type": this.formcontrolValues && this.formcontrolValues.case_type?this.formcontrolValues.case_type:"",
          "chassis_number": this.formcontrolValues && this.formcontrolValues.chasis_no?this.formcontrolValues.chasis_no:"",
          "lass_name": this.formcontrolValues && this.formcontrolValues.lassnme?this.formcontrolValues.lassnme:"",
          "lass_number": this.formcontrolValues && this.formcontrolValues.lassContactNo?this.formcontrolValues.lassContactNo:"",
          "emission_norm":this.formcontrolValues && this.formcontrolValues.emissionNorms?this.formcontrolValues.emissionNorms:"",
          // "sso": this.formcontrolValues && this.formcontrolValues.?this.formcontrolValues:"",
          // "dealer": this.formcontrolValues && this.formcontrolValues.?this.formcontrolValues:"",
          "lob": this.formcontrolValues && this.formcontrolValues.lob?this.formcontrolValues.lob:"",
          "ppl": this.formcontrolValues && this.formcontrolValues.ppl?this.formcontrolValues.ppl:"",
          "pl": this.formcontrolValues && this.formcontrolValues.pl?this.formcontrolValues.pl:"",
          "job_type": this.formcontrolValues && this.formcontrolValues.sr?this.formcontrolValues.sr:"",
          "dealer": this.formcontrolValues && this.formcontrolValues.dealer?this.formcontrolValues.dealer:"",
          "offset":this.pageIndex > 0 ? this.pageIndex * 10:0,
          "limit":10,
          "is_star_guru":this.formcontrolValues && this.formcontrolValues.starguru?this.formcontrolValues.starguru: false,
        }
      let apiQueryParam = "";
      if (queryParamList.length > 0) {
        apiQueryParam = `?` + queryParamList.join("&");
      }

      const url = `${apiUrl}`;
      const apiObj = {
        url: url,
        methodType: HttpMethod.POST,
        requestObj: requestObj,
      };
      this.commonService.commonApiCall(apiObj, (res) => {
        if ( res && res.success ) {
          this.spinner.hide();
          this.recordCount = res.count;
          res.data.forEach((ele) => {
            let obj: any = {
              lassName:`${ele["guru_details"].lass_name}`,
              lassNumber:`${ele["guru_details"].lass_number}`,
              guruId: `${ele["guru_details"].guru_id}`,
              garage: `${ele["guru_details"].garage_name}`,
              guru: `${ele["guru_details"].mechanic_name}`,
              guruNumber:`${ele["guru_details"].phone_number}`,
              emissionNorms:`${ele.emission_norm?ele.emission_norm:"NA"}`,
              partRevenue: `${ele.jobcard_part_revenue.part_revenue}`,
              totaPartPrice: `${ele.jobcard_part_revenue.total_price}`,
              state:`${ele['guru_details'].state_name }`,
              city:`${ele['guru_details'].city_name }`,
              region:`${ele['guru_details'].zone }`,
              pincode:`${ele['guru_details'].pincode }`,
              jobID:`${ele.id?ele.id:"NA"}`,
              jobOpeningDt:this.datePipe.transform(`${ele.created_date }`,"dd/MM/YYYY"),
              jobOpeningTime:this.datePipe.transform(`${ele.created_date }`,"h:mm a"),
              jobCategory:`${ele.case_type?ele.case_type:"NA" }`,
              vehicleRegNum:`${ele.reg_num ?ele.reg_num :"NA"}`,
              vehicleChsisNum:`${ele.chassis_number?ele.chassis_number:"NA" }`,
              lob:`${ele.lob?ele.lob:"NA" }`,
              pl:`${ele.pl?ele.pl:"NA" }`,
              ppl:`${ele.ppl?ele.ppl:"NA" }`,
              labourCharge:`${ele.labour_charges?ele.labour_charges:"NA"}`,
              dealer_name: `${ele["guru_details"].dealer}`, 
              sso:`${ele['guru_details'].sso}`,
              srType:`${ele.job_type}`,
              odometer:`${ele.odometer_reading?ele.odometer_reading:"NA" }`,
              // jobstatus:`${ele.job_status?ele.job_status:"NA" }`,
              jcstatus:`${ele.jc_status?ele.jc_status:"NA" }`,
              failure_reason:`${ele.failure_reason?ele.failure_reason:"NA" }`,
              step_number:`${ele.step_number?ele.step_number:"NA" }`,
              jcnumber:`${ele.jc_number?ele.jc_number:"NA" }`,
              // srstatus:`${ele.sr_status?ele.sr_status:"NA" }`,
              claimstatus:`${ele.claim_status?ele.claim_status:"NA" }`,
              action: `${ele.payment_completed}`,
              feedback:`${ele.feedback?ele.feedback:"NA" }`,
              rating:`${ele.rating?ele.rating:"NA" }`,
              addedby:`${ele.added_by?ele.added_by:"NA" }`,
            };
            this.orders.push(obj);
          });

          this.isDataReady = true;
        } else {
          this.spinner.hide();
          this.isDataReady = true;
        }
      });
    }catch(error){
        this.spinner.hide();
    }
  }
}

export interface Job_Card_Fitement{
Lass_Name:string
Lass_Number:string
Guru_Id: string
Garage: string
Guru: string
Guru_Number:string
Emission_Norms:string
State:string
City:string
Region:string
Pincode:string
Job_ID:string
Part_Revenue:string
Total_Part_Price:string
Job_Opening_Dt:string
Job_Opening_Time:string
Job_Category:string
Vehicle_Registration_Number:string
Vehicle_Chassis_Number:string
Lob:string
Pl:string
Ppl:string
SSO:string;
Dealer_Name:string;
Labour_Charge:string;
Feedback:string;
Rating:string;
AddedBy:string;
}