

import { LatLngLiteral } from '@agm/core';
import { Component, OnInit, ViewChild, Output, Input, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core'
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps'

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss']
})

export class SelectLocationComponent implements OnChanges {
  @Input() newLat: number;
  @Input() newLng: number;
  @Output() coords = new EventEmitter();
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow
  googleMap: google.maps.Map;

  zoom = 5
  center: google.maps.LatLngLiteral
  options: google.maps.MapOptions = {
   // zoomControl: false,
   // scrollwheel: false,
    disableDoubleClickZoom: true,
   // mapTypeId: 'hybrid',
    maxZoom: 15,
    minZoom: 4,
  }
  markers = []
  infoContent = ''

  countryRestriction = {
    latLngBounds: {
      east: 97.4025614766,
      north: 35.4940095078,
      south: 7.96553477623,
      west: 68.1766451354
    },
    strictBounds: true
};
  previous: any;
  ngOnInit() {
    this.newLat = 23.473324
    this.newLng = 77.947998
    // navigator.geolocation.getCurrentPosition((position) => {
      
    //   this.center = {
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   }
      
    // })
  }
  ngOnChanges(changes: SimpleChanges) {
    // this.zoom = 10;
    // for (let property in changes) {
    //     if (property === 'newLat') {
    //      navigator.geolocation.getCurrentPosition((position) => {
    //         this.center = {
    //           lat: +this.newLat,
    //           lng: +this.newLng,
    //         }
    //       })
    //       this.markers=[];
    //       this.markers.push({
    //         position: {
    //           lat: +this.newLat,
    //           lng: +this.newLng,
    //         },
    //         options: {
    //           animation: google.maps.Animation.BOUNCE,
    //         },
    //       })
    //     } 
    // }
    this.newLat = parseFloat(changes.newLat.currentValue);
    this.newLng = parseFloat(changes.newLng.currentValue);
    
  }
  registerMarker(event: google.maps.MapMouseEvent){
    let coorObj:any = {};
    this.newLat = event.latLng.toJSON().lat;
    this.newLng = event.latLng.toJSON().lng;
    coorObj['lat'] = this.newLat;
    coorObj['lng'] = this.newLng;
    this.coords.emit(coorObj);
    this.markers=[];
          this.markers.push({
            position: {
              lat: +this.newLat,
              lng: +this.newLng,
            },
            options: {
              animation: google.maps.Animation.BOUNCE,
            },
          })
  }

  public centerChanged(coords: LatLngLiteral) {
    this.newLat = coords.lat;
    this.newLng = coords.lng;
  }

  clickedMarker(infowindow) {
    this.previous = infowindow;
  }

  // zoomIn() {
  //   if (this.zoom < this.options.maxZoom) this.zoom++
  // }

  // zoomOut() {
  //   if (this.zoom > this.options.minZoom) this.zoom--
  // }

  // click(event: google.maps.MouseEvent) {
  //   console.log(event)
  // }

  // logCenter() {
  //   console.log(JSON.stringify(this.map.getCenter()))
  // }

  // addMarker() {
  //   this.markers.push({
  //     position: {
  //       lat: this.center.lat + ((Math.random() - 0.5) * 2) / 10,
  //       lng: this.center.lng + ((Math.random() - 0.5) * 2) / 10,
  //     },
  //     label: {
  //       color: 'red',
  //       text: 'Marker label ' + (this.markers.length + 1),
  //     },
  //     title: 'Marker title ' + (this.markers.length + 1),
  //     info: 'Marker info ' + (this.markers.length + 1),
  //     options: {
  //       animation: google.maps.Animation.BOUNCE,
  //     },
  //   })
  // }

  // openInfo(marker: MapMarker, content) {
  //   this.infoContent = content
  //   this.info.open(marker)
  // }
}