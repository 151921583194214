import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { HttpMethod } from "../../../constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { takeUntil, take } from "rxjs/operators";
import { DashboardService } from "src/app/services/dashboard.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";

@Component({
  selector: "app-add-rpm",
  templateUrl: "./add-rpm.component.html",
  styleUrls: ["./add-rpm.component.scss"],
  providers: [DatePipe],
})
export class AddRpmComponent implements OnInit {
  addRPMForm: FormGroup;
  submitted: boolean = false;
  // regions: any[] = [];
  role: string;
  submitMessage: string;
  isAddRPM: boolean = false;
  showNodata: boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource = new MatTableDataSource<any>();

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  sub: Subscription = new Subscription();
  currentPage = 0;
  pgIndex: number = 0;
  filterChipList: any[] = [];
  filterObject: any = [];
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  isOpenDropdown:boolean = false;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    // "username",
    "firstName",
     "lastName", 
    //  "email", 
     "Region",
     "phoneNumber", 
    //  "dateJoined"
    "status"
    ];

  websites: any[] = [];

  public websiteMultiCtrl: FormControl = new FormControl(
    "",
    Validators.required
  );
  public websiteMultiFilterCtrl: FormControl = new FormControl();
  public filteredWebsitesMulti: any = new ReplaySubject(1);
  regions = [
    "East",
    "North",
    "South",
    "West"
  ];
  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;

  protected _onDestroy = new Subject();
  states: any;
  region: any;
  rpm: any;
  rpmfilterForm: FormGroup;
  constructor(
    private _formBuilder: FormBuilder,
    private commonService: CommonService,
    private appService: AppService,
    private datePipe: DatePipe,
    private Dashboard:DashboardService,
    private spinner: NgxSpinnerService,

  ) {
    if (localStorage.getItem("userDetail")) {
      this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
      this.region=JSON.parse(localStorage.getItem("userDetail")).userdetails.region;
    }
  }

  ngOnInit(): void {
    // this.getAllRegion();
    this.addRPMForm = this._formBuilder.group({
      is_active: [true],
      is_contractor: [false],
      firstName: [ "",[Validators.required, Validators.pattern(/^[a-zA-Z ]*$/), this.noWhitespaceValidator],],
      lastName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/), this.noWhitespaceValidator]],
      mobileNum: ["",[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.min(6000000000), Validators.maxLength(10),],],
      username: ["", [Validators.required, this.noWhitespaceValidator],],
      email: [""],
      state: ["",  Validators.required],
      region: ["", Validators.required],
    });
    this.rpmfilterForm = this._formBuilder.group({
      mobileNumber: [""]
    });
    this.optyplMaster() 
    this.rpmUserlist();
    //this.websiteMultiCtrl.setValue(this.websites[1]);
    
  }
  // addRPMFormData() {
  //   this.submitted = true;
  //   if (this.addRPMForm.valid) {
  //     if (this.websiteMultiCtrl.valid) {
  //     //console.log(this.addRPMForm.value);
  //     let userObj = {
  //       phone_number: this.addRPMForm.value.mobileNum,
  //       email: this.addRPMForm.value.email,
  //       first_name: this.addRPMForm.value.firstName,
  //       last_name: this.addRPMForm.value.lastName,
  //       user_role: "RPM",
  //       parent_id: "",
  //       loggedin_user_type: this.role,
  //       area: this.websiteMultiCtrl.value,
  //     };
  //     // Subscribeconsole.log(userObj);
  //     const url = `${this.appService.addUser}`;
  //     const apiObj = {
  //       url: url,
  //       methodType: HttpMethod.POST,
  //       requestObj: userObj,
  //     };
  //     this.commonService.commonApiCall(apiObj, (res, error) => {
  //       if (error !== undefined) {
  //         this.showToastermessage = true;
  //         this.showfinalSuccessMsg = "";
  //         this.showfinalErrorMsg = error.error.msg;
  //         setTimeout(() => {
  //           this.showToastermessage = false;
  //         }, 4000);
  //       } else {
  //         this.showfinalErrorMsg = "";
  //         this.showfinalSuccessMsg = res.msg;
  //         this.showToastermessage = true;
  //         setTimeout(() => {
  //           this.showToastermessage = false;
  //         }, 4000);
  //         this.addRPMForm.reset();
  //         this.websiteMultiCtrl.reset();
  //         this.submitted = false;
  //         this.rpmUserlist();
  //       }
  //     });
    
    
  //   }
  //   }
  // }
  addRPMFormData() {
    this.submitted = true;
    if (this.addRPMForm.valid) {
      this.spinner.show();
      let userObj = {
        phone_number: this.addRPMForm.value.mobileNum,
        username: this.addRPMForm.value.username,
        first_name: this.addRPMForm.value.firstName,
        last_name: this.addRPMForm.value.lastName,
        role_id: 1,
        role_name: "RPM",
        is_active: true,
        email_id: this.addRPMForm.value.email,
        state: this.addRPMForm.value.state,
        region: this.addRPMForm.value.region,
        is_contractor: false
      };
  
      this.Dashboard.addDealer(userObj).subscribe({
        next: (response: any) => {
          if (response.success==true) {
            this.spinner.hide();
            this.showfinalErrorMsg = "";
            this.showfinalSuccessMsg = response.msg;
            this.rpmUserlist()
            this.showToastermessage = true;
            this.submitted = false;
            this.addRPMForm.reset({
              is_contractor: false, // Preserve the value
              is_active: true, // Preserve the value
            });
          } else {
            this.spinner.hide();
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = response.error.msg;
            this.showToastermessage = true;
          }
          setTimeout(() => {
            this.showToastermessage = false;
          }, 4000);
        },
        error: (error) => {
          this.spinner.hide();
          this.showfinalSuccessMsg = "";
          if (error.status === 412) {
            this.spinner.hide();
            this.showfinalErrorMsg = "Precondition Failed: " + error.error.msg;
          } else {
            this.spinner.hide();
            this.showfinalErrorMsg = error.error.msg || 'An error occurred';
          }
          this.showToastermessage = true;
          setTimeout(() => {
            this.showToastermessage = false;
          }, 4000);
        }
      });
    }
  }
  
  
  
  optyplMaster() {
    try {
      let data = {};
      this.sub.add(this.Dashboard.optyplMaster(data).subscribe({
        next: (res: any) => {
          if (res && res.success === true) {
            this.states= res.result.state
          }else{} 
        },
        error: (error) => {
          console.log(error);
        }
      }));
    } catch (error) {
      console.log(error);
    }
  }
  toggleLasStatus(isChecked: boolean, row: any) {
    const ListInput = {
      "phone_number": row.username,
      "is_active": isChecked
    };
  
    // Temporarily store the previous state in case we need to revert it
    const previousState = row.is_active;
  
    try {
      this.spinner.show();
      this.sub.add(this.Dashboard.lasStatusUpdate(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response.success==true) {
            row.is_active = isChecked; // Update the state based on the response
            Swal.fire({
              icon: 'success',
              text: 'The Rpm status has been updated.',
              timer: 2000, 
              showConfirmButton: false
            });
          } else {
            this.showToastermessage = true;
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = response.error.msg;
            setTimeout(() => {
              this.showToastermessage = false;
            }, 2000);
            // Revert to previous state
            row.is_active = previousState;
          }
        },
        error: (err) => {
          this.spinner.hide();
          this.showToastermessage = true;
          this.showfinalSuccessMsg = "";
          this.showfinalErrorMsg = "Error. Please try again after some time";
          setTimeout(() => {
            this.showToastermessage = false;
          }, 2000);
          // Revert to previous state
          row.is_active = previousState;
        }
      }))
    }
    catch(error){
      this.spinner.hide();
      // Handle any additional errors
      row.is_active = previousState;
      throw(error);
    }
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    //this.setInitialValue();
  }

  rpmUserlist(page: number = 0, pageSize: number = 10,) {
    const ListInput: any = {} as any;
    ListInput.role="rpm",
    ListInput.phone_number =  this.rpmfilterForm.value.mobileNumber ;
    ListInput.offset = page;
    ListInput.limit = pageSize;
    try {
      this.spinner.show();
      this.sub.add(this.Dashboard.getDealer(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response?.results) {
            this.rpm = response?.results;
            this.dataSource = this.rpm;
            this.totalRows = response?.total_count; 
            this.showNodata = false;
          } else {
            this.rpm = null;
            this.dataSource = null; 
            this.showNodata = true;
          }
        },
        error: (error) => {
          this.spinner.hide();
          throw error;
        }
      }));
    } catch (error) {
      this.spinner.hide();
      throw(error);
    }
  }
  refresh(){
    this.isOpenDropdown = false;
    this.rpmfilterForm.reset();
    this.currentPage = 0;
    this.rpmUserlist()
  }
  applyFilter() {
    this.isOpenDropdown = false;
    this.currentPage = 0;
    this.rpmUserlist()
  }

  

  reset(){
    this.isOpenDropdown = false;
    this.rpmfilterForm.reset();
    this.rpmUserlist()
  }

  openDropdown(){
    this.isOpenDropdown = !this.isOpenDropdown;
  }
  closeDropdown(){
    this.isOpenDropdown = false;
  }

  // pageChanged(event: PageEvent) {
  //   this.pgIndex = event.pageIndex;
    
  //   this.currentPage = event.pageIndex;
  //   this.isLoading = true;
  //   this.rpmUserlist(this.currentPage * this.pageSize, this.pageSize);
  // }
  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.isLoading = true;
    // Multiply pageIndex by pageSize to get the correct offset
    this.rpmUserlist(this.currentPage * this.pageSize, this.pageSize);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // protected setInitialValue() {
  //   this.filteredWebsitesMulti
  //     .pipe(take(1), takeUntil(this._onDestroy))
  //     .subscribe(() => {
  //       this.multiSelect.compareWith = (a: any, b: any) =>
  //         a && b && a.id === b.id;
  //     });
  // }

  protected filterWebsiteMulti() {
    if (!this.websites) {
      return;
    }

    let search = this.websiteMultiFilterCtrl.value;
    if (!search) {
      this.filteredWebsitesMulti.next(this.websites.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredWebsitesMulti.next(
      this.websites.filter((bank) => bank.toLowerCase().indexOf(search) > -1)
    );
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  removeSpace() {
    const inputElement = document.getElementById("username") as HTMLInputElement;
    inputElement.addEventListener("keypress", (event: KeyboardEvent) => {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    });
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
