import { Component, OnInit, Input} from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import Swal from 'sweetalert2';
import {JobCardTGPFitmentComponent} from 'src/app/reports/job-summary/job-card-tgp-fitment/job-card-tgp-fitment.component'
@Component({
  selector: 'app-invoice-payment-list',
  templateUrl: './invoice-payment-list.component.html',
  styleUrls: ['./invoice-payment-list.component.scss']
})
export class InvoicePaymentListComponent implements OnInit {
  @Input() jobIds: any;
  paymentTypes = [
    { key: 'DD', value: 'DD' },
    { key: 'Cheque', value: 'Cheque' },
    { key: 'Cash Payment', value: 'Cash Payment' },
    { key: 'Online Transfer', value: 'Online Transfer' },
    { key: 'Returned', value: 'Returned' },
    { key: 'Mintifi', value: 'Mintifi' }
  ];
  invoicePaymentForm: FormGroup;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  formSubmitted: boolean = false;
  sub: Subscription = new Subscription();

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, 
    private dashboard:DashboardService, private jobcard: JobCardTGPFitmentComponent) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  createForm() {
    this.invoicePaymentForm = this.formBuilder.group({
      payment_method: ['', Validators.required],
      trans_date: ['', Validators.required],
      bank_name: ['',  Validators.maxLength(20)],
      branch_name: ['',  Validators.maxLength(20)],
      reference_number: ['', Validators.maxLength(20)],
      invoice_paid_amount: [450, Validators.required],
      remark: ['', [Validators.required, Validators.maxLength(100)]]
    });
    this.formSubmitted = false;
    this.minDate = {
      year: new Date().getFullYear() - 100,
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };

    this.maxDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  resetForm() {
    this.formSubmitted = false;
    this.invoicePaymentForm.patchValue({
      payment_method: '',
      trans_date: '',
      bank_name: '',
      branch_name: '',
      reference_number: '',
      invoice_paid_amount: 450,
      remark: ''
    });
  }
  private formatTransactionDate(date: NgbDateStruct): string {
    const formattedDay = date.day < 10 ? `0${date.day}` : date.day.toString();
    const formattedMonth = date.month < 10 ? `0${date.month}` : date.month.toString();
  
    // Get the current date and time
    const currentDateTime = new Date();
  
    // Convert the current time to the UTC+5:30 time zone
    const timezoneOffset = 330; // 5 hours and 30 minutes in minutes
    const offsetMilliseconds = timezoneOffset * 60 * 1000;
    const utc5_30DateTime = new Date(currentDateTime.getTime() + offsetMilliseconds);
  
    // Format the time part
    const formattedTime = utc5_30DateTime.toISOString().slice(11, 23);
  
    // Construct the final date-time string
    const formattedDate = `${date.year}-${formattedMonth}-${formattedDay} ${formattedTime}`;
    return formattedDate;
  }

  saveForm() {
    this.formSubmitted = true;
    
    if (this.invoicePaymentForm.valid) {
      
      const formData = this.invoicePaymentForm.value;
      const ListInput = {
        job_id: this.jobIds,
        mode_of_payment: formData.payment_method,
        dealer_status: 'Paid',
        claim_amount: formData.invoice_paid_amount,
        bank_name: formData.bank_name || "",
        branch_name: formData.branch_name || "",
        reference_number: formData.reference_number || "",
        transaction_date: this.formatTransactionDate(formData.trans_date),
        remarks: formData.remark,
        // invoice_paid_amount: "450"
      };
  
      this.dashboard.updateClaim(ListInput).subscribe({
        next: (response) => {
          if (response.success === true) {
            Swal.fire({
              icon: 'success',
              title: 'Payment Successful',
              text: response.message,
              timer: 2000,
              showConfirmButton: false
            }).then(() => {
              this.jobcard.getJobSummaryTableData();
              this.closeModal();
            });
          } else if(response.success === false){
            Swal.fire({
              icon: 'error',
              title: 'Payment Not Updated',
              text: response.message,
              timer: 2000,
              showConfirmButton: false
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Payment Not Updated',
              text: response.message,
              timer: 2000,
              showConfirmButton: false
            });
          }
        },
        error: (err) => { }
      });
    } else {
      // Form is invalid, no need to do anything
    }
  }

}

