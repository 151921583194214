import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { HttpMethod } from "../constants/http-handlers";
import { AppService } from "../services/app.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  loginUser: any;
  userimage: string;
  userdetail: object;
  globalSearchMessage: string;
  role: string;
  multiLoggedin: boolean;
  showNotifications: boolean = false;
  roleBasedUserList: any[] = [];
  roleBasedUserForm: FormGroup;
  globalUserRoleId: any;

  constructor(
    private router: Router,
    public commonService: CommonService,
    private appService: AppService
  ) {
    
    this.loginUser = JSON.parse(localStorage.getItem("loginUser"));
    this.userdetail = JSON.parse(localStorage.getItem("userDetail"));

    if (localStorage.getItem("userDetail")) {
      this.userimage = JSON.parse(
        localStorage.getItem("userDetail")
      ).profile_image;
      this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
    }
  }
  globalSearchText: string;
  ngOnInit(): void {
    this.commonService.globalSearchMessage.subscribe(
      (message) => (this.globalSearchText = message)
    );
    // this.commonService.multiLoggedin$.subscribe(
    //   (res) => {this.multiLoggedin = res;
    // });'
    this.commonService.globalselectedUseroleId.subscribe(
      (message) => (this.globalUserRoleId = message)
    );
    if (localStorage.getItem("userDetail")){
      this.getselectRoleUserListitem();
      this.roleBasedUserForm = new FormGroup({
        roleBasedUserItem: new FormControl(""),
      });
    }
    
  }

  getselectRoleUserListitem() {
    const url = `${this.appService.roleBasedUser}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      this.roleBasedUserList = res;
    });
  }
  getroleUserItem() {
    this.commonService.changeselectedUseroleId(
      this.roleBasedUserForm.value.roleBasedUserItem
    );
    this.router.navigate(["dashboard"]);
  }

  logOutUser() {
    this.commonService.changeMessage("");
    this.commonService.changeselectedUseroleId("");
    const url = this.appService.logout;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
    };
    this.commonService.commonApiCall(apiObj, (res, error) => {
      localStorage.clear();
      this.router.navigate(["/", "login"]);
    });
  }
  searchData() {
    this.commonService.changeMessage(this.globalSearchText.trim());
    this.router.navigate(["guru-list"]);
    // location.href = '/guru-list';
  }
  checkText(event: KeyboardEvent) {
    //debugger;
    if (
      event.code == "Enter" ||
      (event.code == "Backspace" && this.globalSearchText.trim() == "")
    ) {
      this.searchData();
    }
  }
  // showHideNotifications() {
  //   this.showNotifications = !this.showNotifications;
  // }
  keyPressSpclCharacter(event) {
    var k = event.which ? event.which : event.keyCode;
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
  redirectToprofile() {
    this.router.navigate(["profile"]);
  }
}
