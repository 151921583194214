<div class="tb-filter-wrapper">
    <button mat-stroked-button class="primary-stroke-button filterBtn" (click)="openDropdown()"
        [ngClass]="{ open: isOpendropdown }">
        Filter
    </button>
    <div class="custom-filter-sec" *ngIf="isOpendropdown">
        <h3>
            Filter <button class="filterCloseBtn" (click)="closeDropdown()"></button>
        </h3>
        <form [formGroup]="subdashfilter" name="doorStepFilterForm" class="form">
            <div class="custom-filter-body" style="justify-content: space-evenly;">
                <div class="custom-filter-col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="toDatePick" placeholder="From Date" formControlName="fromDate" [max]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="toDatePick" [disabled]="false"></mat-datepicker-toggle>
                        <mat-datepicker #toDatePick></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label label for="state" class="form-label"> Select State <span
                          class="requiredfield">*</span></mat-label>
                      <mat-select formControlName="state_name"(selectionChange)="stateSelected = true;this.dropdown('lass_name')" multiple>
                        <!-- <mat-option value="one">Please select State</mat-option> -->
                        <mat-option [value]="state" *ngFor="let state of states">{{
                          state}}</mat-option>
                      </mat-select>
                    </mat-form-field>

                      <mat-form-field>
                        <mat-label label for="lob" class="form-label"> Select Lob <span
                            class="requiredfield">*</span></mat-label>
                        <mat-select formControlName="lob"multiple>
                          <!-- <mat-option value="one">Please select lob</mat-option> -->
                          <mat-option [value]="lob" *ngFor="let lob of lobData">{{
                           lob }}</mat-option>
                        </mat-select>
                      </mat-form-field>

                    

                         
                     <mat-form-field>
                        <mat-label label for="PPl" class="form-label"> Select PPL <span
                            class="requiredfield">*</span></mat-label>
                        <mat-select formControlName="ppl" multiple>
                          <!-- <mat-option value="one">Please select ppl</mat-option> -->
                          <mat-option [value]="ppl" *ngFor="let ppl of ppldata">{{
                            ppl }}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label label for="region" class="form-label"> Select Emission Norm <span
                            class="requiredfield">*</span></mat-label>
                        <mat-select formControlName="emission_norm" multiple>
                          <!-- <mat-option value="one">Please select Emission Norm</mat-option> -->
                          <mat-option [value]="item" *ngFor="let item of emissionormdata">{{
                            item }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field *ngIf="role === 'SuperAdmin'">
                        <mat-label>Dealer Username</mat-label>
                        <input matInput placeholder="Enter Dealer Username" value=""
                        formControlName="dealer">
                      </mat-form-field>
                      <mat-checkbox formControlName="starguru" color="primary" style="margin-top: 15px;" (change)="updateStarguru(); applyFilter()">Star Guru</mat-checkbox>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="fromDatePick" placeholder="To Date" formControlName="toDate" [max]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="fromDatePick" [disabled]="false"></mat-datepicker-toggle>
                        <mat-datepicker #fromDatePick></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label label for="pl" class="form-label"> Select Pl <span
                            class="requiredfield">*</span></mat-label>
                        <mat-select formControlName="pl" multiple>
                          <!-- <mat-option value="one">Please select pl</mat-option> -->
                          <mat-option [value]="pl" *ngFor="let pl of pldata">{{
                            pl }}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>Lass Mobile No</mat-label>
                        <input matInput placeholder="Enter Lass Mobile No." value=""
                        formControlName="lass_number">
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label label for="region" class="form-label"> Select Problem Name <span
                            class="requiredfield">*</span></mat-label>
                    <mat-select formControlName="problem_name" >
                          <!-- <mat-option value="one">Please select Problem</mat-option> -->
                          <mat-option [value]="problem" *ngFor="let problem of ProblemData">{{
                            problem }}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>LASS Name </mat-label>
                        <input matInput placeholder="Enter LASS Name" value="" formControlName="lass_name"
                          (input)="dropdown('lass_name')" [readonly]="!stateSelected" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor="let item of lassName" [value]="item">{{item}}</mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      
                      <mat-form-field>
                        <mat-label label for="sr" class="form-label"> Select Service Type <span
                            class="requiredfield">*</span></mat-label>
                        <mat-select formControlName="sr" multiple>
                          <mat-option value="First Free Service">First Free Service</mat-option>
                          <mat-option value="Second Free Service">Second Free Service</mat-option>
                          <mat-option value="Third Free Service">Third Free Service</mat-option>
                          <mat-option value="Paid Service">Paid Service</mat-option>
                          <mat-option value="Break-down">Break-down</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
            </div>
           

        
       
            <div class="btn-group filterBtn-group">
                <button mat-stroked-button class="primary-stroke-button" (click)="reset()">Reset</button>
                <button mat-flat-button class="primary-flat-button" (click)="applyFilter()">Apply</button>
            </div>
       
        </form>
    </div>
</div>

<div class="action-toaster" *ngIf="showToastermessage" style="z-index: 9999;">
    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
        {{ showfinalErrorMsg }}
    </div>
</div>