<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">Claim status Details</h3>
        <button type="button" class="btn-close" (click)="close()"></button>
    </div>
    <div class="modal-body">

        <div>
            <p><span style="font-weight: 600;">Claim Status: </span>{{claimData.CLAIM_STATUS_s || 'NA'}}</p>
            <p><span style="font-weight: 600;">Service Dealer Code: </span>{{claimData.SERVICE_DEALER_CODE_s || 'NA'}}</p>
            <p><span style="font-weight: 600;">FSB Creation Date: </span>{{(claimData.FSB_CREATION_DATE_dt).substr(0,10) || 'NA'}}</p>
            <p><span style="font-weight: 600;">Invoice Number: </span>{{claimData.INVOICE_NO_s || 'NA'}}</p>
            <p><span style="font-weight: 600;">VCM Comments: </span>{{claimData.VCM_COMMENTS_s || 'NA'}}</p>
            
        </div>
    </div>
</div>
